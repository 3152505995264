import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { Jobs } from '../../../models/film/jobs.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormValidationService } from '../../../../form/services/validation.service';
import { FormShareService } from '../../../../form/services/share.service';
import { formTypes } from '../../../../form/form.constants';
import { ProjectForm } from '../../../../project/models/form.model';

@Component({
  selector: 'fl-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit, OnDestroy {
  @Input() jobs: Jobs;
  @Input() form: ProjectForm;
  @Input() readOnly = false;
  jobsForm: FormGroup;
  private formGroupSubscription: Subscription;
  isAudit: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private validationService: FormValidationService,
    private formService: FormShareService
  ) {}

  ngOnInit() {
    this.isAudit = this.form.type === formTypes.audit.abbrev ? true : false;
    this.jobsForm = this.formBuilder.group({
      aboveTheLineJobs: [this.jobs.aboveTheLineJobs, Validators.required],
      belowTheLineJobs: [this.jobs.belowTheLineJobs, Validators.required],
      extras: [this.jobs.extras, Validators.required]
    });
    this.formGroupSubscription = this.jobsForm.valueChanges.subscribe(value => {
      // Compute the value
      const computedTotal = (value.aboveTheLineJobs || 0) + (value.belowTheLineJobs || 0) + (value.extras || 0);

      // Update model changes
      Object.assign(this.jobs, value, { totalJobs: computedTotal });
    });
    this.validationService.form.setControl('jobs', this.jobsForm);
    this.cdr.detectChanges();
    if (this.readOnly) {
      setTimeout(() => this.jobsForm.disable(), 0);
    }
  }

  ngOnDestroy() {
    this.validationService.form.removeControl('jobs');
  }
}
