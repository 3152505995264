import { ProjectInfoBase } from '../../../project/models/project-info-base.model';
import { Address } from '../../../shared/models/address.model';
import { EntertainmentId } from '../../interfaces/ent-id.interface';

export class ProjectInfo extends ProjectInfoBase implements EntertainmentId {
    filmId: string;
    permanentMailingAddress: Address;
    entId: string;
    finalCertTemplateType: string;
    finalCertTemplateText: string;
    finalCertCreditsGrantedDollars: number;
    finalCertCreditsReleased: boolean;
    finalCertCreditsReleasedYear: number;
    finalCertCreditsReleasedDollars: number;
    finalCertFutureYearCreditReleases: boolean;
    finalCertFutureYearCreditReleasesYear: number;
    finalCertFutureYearCreditReleasesDollars: number;
    projectCompletionAndFileClosureParagraph: string;

    constructor(info?: any) {
        info = info || {};

        super(info);

        this.filmId = info.filmId || null;
        this.permanentMailingAddress = new Address(info.permanentMailingAddress) || new Address();
        this.entId = this.filmId;
        this.finalCertTemplateType = info.finalCertTemplateType;
        this.finalCertTemplateText = info.finalCertTemplateText;
        this.finalCertCreditsGrantedDollars = info.finalCertCreditsGrantedDollars;
        this.finalCertCreditsReleased = info.finalCertCreditsReleased;
        this.finalCertCreditsReleasedYear = info.finalCertCreditsReleasedYear;
        this.finalCertCreditsReleasedDollars = info.finalCertCreditsReleasedDollars;

        this.finalCertFutureYearCreditReleases = info.finalCertFutureYearCreditReleases;
        this.finalCertFutureYearCreditReleasesYear = info.finalCertFutureYearCreditReleasesYear;
        this.finalCertFutureYearCreditReleasesDollars = info.finalCertFutureYearCreditReleasesDollars;
        this.projectCompletionAndFileClosureParagraph = info.projectCompletionAndFileClosureParagraph;

      }
}
