import { navigationData } from "app/core/core.constants";

// New Permissions applying to all users potentially
export class RealPermissions {
  canPerformActions: boolean;
  canViewConfidentialProjects: boolean;
  canPerformEzActions: boolean;
  canPerformQjActions: boolean;
  canPerformIteActions: boolean;
  canPerformRtaActions: boolean;
  canPerformFilmActions: boolean;
  canPerformDigitalActions: boolean;
  canPerformStepActions: boolean;
  canDigitalReviewOne: boolean;
  canDigitalReviewTwo: boolean;
  canResetTwoFactor: boolean;
}

export class UserPermissions extends RealPermissions {
  applicant = true; // temporary property to enable all applicant features for all users
  biDashBoard: boolean;
  stepDashBoard: boolean;
  board: boolean;
  codeTables: boolean;
  createNewUser: boolean;
  entDashBoard: boolean;
  externalReview: boolean;
  formReview: boolean;
  iteTaxRates: boolean;
  payments: boolean;
  projectAccess: boolean;
  reports: boolean;
  search: boolean;
  signatures: boolean;
  systemSettings: boolean;
  userAccounts: boolean;
  verificationReports: boolean;
  projectSummaryReadWrite: boolean;
  projectSummaryActions: boolean;
  filmCreditTracking: boolean;
  lookupTables: boolean;
  viewCharts: boolean;


  constructor(permissions?: any) {
    super();

    if (!permissions) {
      permissions = {};
    }
    this.biDashBoard = permissions.biDashBoard || false;
    this.stepDashBoard = permissions.stepDashBoard || false;
    this.board = permissions.board || false;
    this.codeTables = permissions.codeTables || false;
    this.iteTaxRates = true; // todo: add to api
    this.createNewUser = permissions.createNewUser || false;
    this.entDashBoard = permissions.entDashBoard || false;
    this.externalReview = permissions.externalReview || false;
    this.formReview = permissions.formReview || false;
    this.payments = permissions.payments || false;
    this.projectAccess = permissions.projectAccess || false;
    this.reports = permissions.reports || false;
    this.search = permissions.search || false;
    this.signatures = permissions.signatures || false;
    this.systemSettings = permissions.systemSettings || false;
    this.userAccounts = permissions.userAccounts || false;
    this.verificationReports = permissions.verificationReports || false;
    this.projectSummaryReadWrite = permissions.projectSummaryReadWrite || false;
    this.canPerformActions = permissions.canPerformActions || false;
    this.canViewConfidentialProjects =
      permissions.canViewConfidentialProjects || false;
    this.projectSummaryActions = permissions.projectSummaryActions || false;
    this.filmCreditTracking = permissions.filmCreditTracking || false;
    this.lookupTables = permissions.lookupTables || false;

    this.canPerformEzActions = permissions.canPerformEzActions || false;
    this.canPerformQjActions = permissions.canPerformQjActions || false;
    this.canPerformIteActions = permissions.canPerformIteActions || false;
    this.canPerformRtaActions = permissions.canPerformRtaActions || false;
    this.canPerformFilmActions = permissions.canPerformFilmActions || false;
    this.canPerformDigitalActions = permissions.canPerformDigitalActions || false;
    this.canPerformStepActions = permissions.canPerformStepActions || false;
    this.canDigitalReviewOne = permissions.canDigitalReviewOne || false;
    this.canDigitalReviewTwo = permissions.canDigitalReviewTwo || false;
    this.canResetTwoFactor = permissions.canResetTwoFactor || false;
    this.viewCharts = permissions.viewCharts || false;
  }
}
