import { userType } from '../user/user.constants';
import {
  externalReviewAgencies,
  externalReviewStatuses,
  auditDepositTransactionActions
} from '../project/project.constants';
import { programTypes } from '../shared/shared.constants';
import {
  formTypes,
  formStatuses,
  formCertificationStatus
} from '../form/form.constants';
import { boardStatus } from '../management/management.constants';

//#region Types

export type CarbonCopyUserType =
  | 'applicant'
  | 'manager'
  | 'lga'
  | 'assessor'
  | 'ldr'
  | 'lwc'
  | 'board';
//#endregion

//#region Constants
// This is a superset of user types and external agencies
export const carbonCopyUserTypes = {
  [userType.applicant.code]: <CarbonCopyUserType>userType.applicant.code,
  [userType.management.code]: <CarbonCopyUserType>userType.management.code,
  [userType.assessor.code]: <CarbonCopyUserType>userType.assessor.code,
  [userType.board.code]: <CarbonCopyUserType>userType.board.code,
  [externalReviewAgencies.lga.abbrev]: <CarbonCopyUserType>(
    externalReviewAgencies.lga.abbrev
  ),
  [externalReviewAgencies.lwc.abbrev]: <CarbonCopyUserType>(
    externalReviewAgencies.lwc.abbrev
  ),
  [externalReviewAgencies.ldr.abbrev]: <CarbonCopyUserType>(
    externalReviewAgencies.ldr.abbrev
  )
};

export const programFormUserTypeMap = {
  [formStatuses.informationRequired.name]: {
    [programTypes.ite.name]: {
      [formTypes.advance.abbrev]: [carbonCopyUserTypes[userType.assessor.code]],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.assessor.code]],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code]
      ],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.assessor.code]]
    },
    [programTypes.rta.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.afc.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.pcr.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.ez.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.qj.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.film.name]: {
      [formTypes.application.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.dm.name]: {
      [formTypes.application.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.step.name]: {
      [formTypes.application.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    }
  },

  [formStatuses.reviewOneComplete.name]: {
    [programTypes.dm.name]: {}
  },
  [formStatuses.reviewTwoComplete.name]: {
    [programTypes.dm.name]: {}
  },

  [formStatuses.reviewComplete.name]: {
    [programTypes.ite.name]: {
      [formTypes.advance.abbrev]: [carbonCopyUserTypes[userType.assessor.code]],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.assessor.code]],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code]
      ],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.assessor.code]]
    },
    [programTypes.rta.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.afc.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.pcr.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.ez.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.qj.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.film.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.dm.name]: {},
    [programTypes.step.name]: {}
  },
  [formStatuses.onHold.name]: {
    [programTypes.ite.name]: {
      [formTypes.advance.abbrev]: [carbonCopyUserTypes[userType.assessor.code]],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.assessor.code]],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code]
      ],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.assessor.code]]
    },
    [programTypes.rta.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.afc.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.pcr.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.ez.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.qj.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.film.name]: {},
    [programTypes.dm.name]: {},
    [programTypes.step.name]: {}
  },
  [formStatuses.cancelled.name]: {
    [programTypes.ite.name]: {
      [formTypes.advance.abbrev]: [carbonCopyUserTypes[userType.assessor.code]],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.assessor.code]],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code]
      ],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.assessor.code]]
    },
    [programTypes.rta.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.afc.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ],
      [formTypes.pcr.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.ez.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.qj.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev]
      ]
    },
    [programTypes.film.name]: {},
    [programTypes.dm.name]: {},
    [programTypes.step.name]: {}
  },
  [formStatuses.denied.name]: {
    [programTypes.ite.name]: {},
    [programTypes.rta.name]: {},
    [programTypes.ez.name]: {},
    [programTypes.qj.name]: {},
    [programTypes.film.name]: {},
    [programTypes.dm.name]: {},
    [programTypes.step.name]: {}
  },
  [formStatuses.auditorAssigned.name]: {
    [programTypes.film.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.dm.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    }
  },
  [formStatuses.awaitingAudit.name]: {
    [programTypes.film.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.dm.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    }
  },
  [formStatuses.received.name]: {
    [programTypes.film.name]: {
      [formTypes.application.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.dm.name]: {
      [formTypes.application.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    }
  },
  [formStatuses.depositReceived.name]: {
    [programTypes.film.name]: {
      [formTypes.application.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.dm.name]: {
      [formTypes.application.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    }
  },
  [formCertificationStatus.certified.display]: {
    [programTypes.ez.name]: {
      [formTypes.ecr.abbrev]: [
        carbonCopyUserTypes[externalReviewAgencies.ldr.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ]
    },
    [programTypes.qj.name]: {
      [formTypes.ecr.abbrev]: [
        carbonCopyUserTypes[externalReviewAgencies.ldr.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.acr.abbrev]: [
        carbonCopyUserTypes[externalReviewAgencies.ldr.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ]
    }
  },
  [externalReviewStatuses.objection.displayName]: {
    [programTypes.ez.name]: {
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.qj.name]: {
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.rta.name]: {
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.ite.name]: {
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    }
  },
  [externalReviewStatuses.objectionLifted.displayName]: {
    [programTypes.ez.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.balanceAdjustmentRequest.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.extensionRequest.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.qj.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.balanceAdjustmentRequest.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.extensionRequest.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.rta.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.balanceAdjustmentRequest.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.extensionRequest.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.ite.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.balanceAdjustmentRequest.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.extensionRequest.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.management.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    }
  },
  [externalReviewStatuses.pendingReview.displayName]: {
    [programTypes.ez.name]: {
      [formTypes.advance.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.balanceAdjustmentRequest.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.extensionRequest.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.external.code]]
    },
    [programTypes.qj.name]: {
      [formTypes.advance.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.balanceAdjustmentRequest.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.extensionRequest.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.external.code]]
    },
    [programTypes.rta.name]: {
      [formTypes.advance.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.balanceAdjustmentRequest.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.extensionRequest.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.external.code]]
    },
    [programTypes.ite.name]: {
      [formTypes.advance.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.balanceAdjustmentRequest.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.extensionRequest.abbrev]: [
        carbonCopyUserTypes[userType.external.code]
      ],
      [formTypes.acr.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.ecr.abbrev]: [carbonCopyUserTypes[userType.external.code]],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.external.code]]
    }
  },
  [auditDepositTransactionActions.OutgoingpaymentRequest]: {
    [programTypes.film.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.dm.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    }
  },
  [auditDepositTransactionActions.OutgoingpaymentCancelled]: {
    [programTypes.film.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.dm.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    }
  },
  [auditDepositTransactionActions.requestRefund]: {
    [programTypes.film.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.dm.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    }
  },
  [auditDepositTransactionActions.requestPayment]: {
    [programTypes.film.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    },
    [programTypes.dm.name]: {
      [formTypes.audit.abbrev]: [carbonCopyUserTypes[userType.management.code]]
    }
  },
  [boardStatus.Approved.name]: {
    [programTypes.ite.name]: {
      [formTypes.advance.abbrev]: [carbonCopyUserTypes[userType.assessor.code],
      carbonCopyUserTypes[userType.management.code]],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.assessor.code],
      carbonCopyUserTypes[userType.management.code]],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.assessor.code],
      carbonCopyUserTypes[userType.management.code]],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.partialTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.fullTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.specialRequest.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ]
    },
    [programTypes.rta.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.pcr.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.partialTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.fullTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ]
    }
    ,
    [programTypes.qj.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.pcr.abbrev]: [
        carbonCopyUserTypes[userType.management.code]],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.partialTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.fullTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.specialRequest.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ]
    }
  },


  [boardStatus.Deferred.name]: {
    [programTypes.ite.name]: {
      [formTypes.advance.abbrev]: [carbonCopyUserTypes[userType.assessor.code],
      carbonCopyUserTypes[userType.management.code]],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.assessor.code],
      carbonCopyUserTypes[userType.management.code]],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.assessor.code],
      carbonCopyUserTypes[userType.management.code]],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.partialTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.fullTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.specialRequest.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ]
    }, [programTypes.qj.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.pcr.abbrev]: [
        carbonCopyUserTypes[userType.management.code]],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.partialTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.fullTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.specialRequest.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ]
    }},

  [boardStatus.Denied.name]: {
    [programTypes.ite.name]: {
      [formTypes.advance.abbrev]: [carbonCopyUserTypes[userType.assessor.code],
      carbonCopyUserTypes[userType.management.code]],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [carbonCopyUserTypes[userType.assessor.code],
      carbonCopyUserTypes[userType.management.code]],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.pcr.abbrev]: [carbonCopyUserTypes[userType.assessor.code],
      carbonCopyUserTypes[userType.management.code]],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.partialTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.fullTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.specialRequest.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[userType.management.code]
      ]
    },
    [programTypes.rta.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.pcr.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.partialTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.fullTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.assessor.code],
        carbonCopyUserTypes[externalReviewAgencies.lga.abbrev],
        carbonCopyUserTypes[userType.management.code]
      ]
    },
    [programTypes.qj.name]: {
      [formTypes.advance.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.application.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.afc.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.renewalApplication.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.pcr.abbrev]: [
        carbonCopyUserTypes[userType.management.code]],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeAffiliatesOwners.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeCompanyName.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.changeLocation.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.partialTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.fullTransferOwnership.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.specialRequest.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ],
      [formTypes.contractClosure.abbrev]: [
        carbonCopyUserTypes[userType.management.code]
      ]
    }
  }
};

//#endregion
