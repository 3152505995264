/**
 * VERY IMPORTANT *
 * the web api application has the same enum types for EmailTemplate and ReportTemplate
 * In order for angular to translate to .NET properly, the angular enums and the .NET
 * enums values MUST BE IDENTICAL!!!
 *
 * These are not required to be in alphabetical order.
 * The requirements are:
 * 1) the enum values (0, 1, 2, etc.) are consistent in both here and in .NET
 */

// This enum should have one entry for each email view in WebApi.Views.Email
export enum EmailTemplate {
  AdditionalPaymentReceived = 0,
  AdditionalPaymentRequested = 1,
  AdvanceReceived = 2,
  BoardApprovedContractApplication = 3,
  BoardApprovedContractChange = 4,
  BoardApprovedContractClosure = 5,
  BoardNotice = 6,
  BusinessSignatureRequired = 7,
  ExternalReviewObjection = 8,
  ForgotPassword = 9,
  FormStatusChange = 10,
  NoticeOfAffidavitOfAnnualCertification = 11,
  NoticeOfEcrCertification = 12,
  PaymentRequired = 13,
  ProjectAccessApproved = 14,
  ProjectAccessDenied = 15,
  ProjectAccessRequest = 16,
  ProjectAttachmentsUploaded = 17,
  ProjectsReadyForExternalReview = 18,
  PublishBoardAgenda = 19,
  SubmissionSuccessful = 20,
  UserAccountAlreadyExists = 21,
  UserAccountCreated = 22,
  UserAccountEmailVerification = 23,
  SignatoryRefusedToSign = 24,
  PasswordResetSuccessfully = 25,
  NoticeOfAcrCertification = 26,
  EsignSentForSignature = 27,
  EsignExecuted = 28,
  ExternalReviewObjectionLifted = 29,
  PhotographyStartConfirmationReminder = 30,
  CostReportSubmissionReminder = 31,
  NextApplicationNeededReminder = 32,
  RefundPayment = 33,
  AuditorAssigned = 34,
  AwaitingAudit = 35,
  AuditReceived = 36,
  CPAPayment = 37,
  CPAPaymentCancellation = 38,
  EsignSentToCompany = 39,
  AddDMAttachment = 40,
  COCExtensionRequested = 41,
  AuditPayment = 42,
  TwoFactorReset = 43
}

// This enum should have one entry for each report view in WebApi.Views.Report
export enum ReportTemplate {
  QJAct386ApplicationReport = 0,
  QJAct387ApplicationReport = 1,
  QJAdvanceReport = 2
}
