import { userType } from '../user/user.constants';
import { FormStatusDetails } from './form.types';

export type FormAction =
  | 'addENTId'
  | 'close'
  | 'no-sign'
  | 'pay'
  | 'print'
  | 'request-payment'
  | 'request-signature'
  | 'set-received'
  | 'sendIrrevocableDesigneeAgreement'
  | 'save'
  | 'save-credits'
  | 'sign'
  | 'submit'
  | 'submitCostReport'
  | 'complete-review'
  | 'unlock'
  | 'completeAudit'
  | 'createInitialCertLetter'
  | 'resendInitialCertLetter'
  | 'createFinalCertAttestation'
  | 'createFinalCertLetter';

export type FormStatus =
  | 'Pending'
  | 'Pending Signature'
  | 'Pending Payment'
  | 'Pending Submit'
  | 'Received'
  | 'Information Required'
  | 'Review Complete'
  | 'On Hold'
  | 'Cancelled'
  | 'Deposit Received'
  | 'Auditor Assigned'
  | 'Awaiting Audit'
  | 'Awaiting Expenditure Verification Report'
  | 'Denied';

export type FormType =
  | 'audit'
  | 'acr'
  | 'advance'
  | 'afc'
  | 'application'
  | 'asa'
  | 'bar'
  | 'cao'
  | 'ccc'
  | 'ccl'
  | 'ccn'
  | 'cft'
  | 'coc'
  | 'cpt'
  | 'coc'
  | 'ecr'
  | 'ext'
  | 'evr'
  | 'initialCert'
  | 'pcr'
  | 'req'
  | 'rnw'
  | 'stepApplication';

// Latest form statuses based on tds site.
export const formStatuses: FormStatusDetails = {
  pending: {
    name: <FormStatus>'Pending',
    order: 0
  },
  pendingSignature: {
    name: <FormStatus>'Pending Signature',
    order: 1
  },
  pendingPayment: {
    name: <FormStatus>'Pending Payment',
    order: 2
  },
  pendingSubmit: {
    name: <FormStatus>'Pending Submit',
    order: 3
  },
  received: {
    name: <FormStatus>'Received',
    order: 4
  },
  informationRequired: {
    name: <FormStatus>'Information Required',
    order: 5
  },
  reviewComplete: {
    name: <FormStatus>'Review Complete',
    order: 6
  },
  onHold: {
    name: <FormStatus>'On Hold',
    order: 7
  },
  cancelled: {
    name: <FormStatus>'Cancelled',
    order: 8
  },
  denied: {
    name: <FormStatus>'Denied',
    order: 9
  },
  depositReceived: {
    name: <FormStatus>'Deposit Received',
    order: 10
  },
  auditorAssigned: {
    name: <FormStatus>'Auditor Assigned',
    order: 11
  },
  awaitingAudit: {
    name: <FormStatus>'Awaiting Audit',
    order: 12
  },
  awaitingExpenditureVerification: {
    name: <FormStatus>'Awaiting Expenditure Verification Report',
    order: 13
    },
    reviewOneComplete: {
        name: <FormStatus>'Review One Complete',
        order: 14
    },
    reviewTwoComplete: {
        name: <FormStatus>'Review Two Complete',
        order: 15
    }
};

export const stepFormStatuses: FormStatusDetails = {
  pending: {
    name: <FormStatus>'Pending',
    order: 0
  },
  pendingSignature: {
    name: <FormStatus>'Pending Signature',
    order: 1
  },
  pendingSubmit: {
    name: <FormStatus>'Pending Submit',
    order: 2
  },
  received: {
    name: <FormStatus>'Received',
    order: 3
  },
  informationRequired: {
    name: <FormStatus>'Information Required',
    order: 4
  },
  reviewComplete: {
    name: <FormStatus>'Review Complete',
    order: 5
  },
  onHold: {
    name: <FormStatus>'On Hold',
    order: 6
  },
  cancelled: {
    name: <FormStatus>'Cancelled',
    order: 7
  },
  denied: {
    name: <FormStatus>'Denied',
    order: 8
  }
};

export const entFormStatuses: FormStatusDetails = {
  pending: {
    name: <FormStatus>'Pending',
    order: 0
  },
  pendingSignature: {
    name: <FormStatus>'Pending Signature',
    order: 1
  },
  pendingPayment: {
    name: <FormStatus>'Pending Payment',
    order: 2
  },
  received: {
    name: <FormStatus>'Received',
    order: 3
  },
  informationRequired: {
    name: <FormStatus>'Information Required',
    order: 4
  },
  reviewComplete: {
    name: <FormStatus>'Review Complete',
    order: 5
  },
  depositReceived: {
    name: <FormStatus>'Deposit Received',
    order: 6
  },
  auditorAssigned: {
    name: <FormStatus>'Auditor Assigned',
    order: 7
  },
  awaitingAudit: {
    name: <FormStatus>'Awaiting Audit',
    order: 8
  },
  denied: {
    name: <FormStatus>'Denied',
    order: 9
  },
  awaitingExpenditureVerification: {
    name: <FormStatus>'Awaiting Expenditure Verification Report',
    order: 10
  }
};

export const dmFormStatuses: FormStatusDetails = {
  pending: {
    name: <FormStatus>'Pending',
    order: 0
  },
  pendingSignature: {
    name: <FormStatus>'Pending Signature',
    order: 1
  },
  pendingPayment: {
    name: <FormStatus>'Pending Payment',
    order: 2
  },
  received: {
    name: <FormStatus>'Received',
    order: 3
  },
  informationRequired: {
    name: <FormStatus>'Information Required',
    order: 4
  },
  reviewComplete: {
    name: <FormStatus>'Review Complete',
    order: 5
  },
  depositReceived: {
    name: <FormStatus>'Deposit Received',
    order: 6
  },
  auditorAssigned: {
    name: <FormStatus>'Auditor Assigned',
    order: 7
  },
  awaitingAudit: {
    name: <FormStatus>'Awaiting Audit',
    order: 8
  },
  denied: {
    name: <FormStatus>'Denied',
    order: 9
  },
  awaitingExpenditureVerification: {
    name: <FormStatus>'Awaiting Expenditure Verification Report',
    order: 10
  },
  reviewOneComplete: {
    name: <FormStatus>'Review One Complete',
    order: 13
  },
  reviewTwoComplete: {
    name: <FormStatus>'Review Two Complete',
    order: 14
  },
};

export const formStatus = {
  pending: <FormStatus>'Pending',
  pendingSignature: <FormStatus>'Pending Signature',
  pendingPayment: <FormStatus>'Pending Payment',
  pendingSubmit: <FormStatus>'Pending Submit',
  received: <FormStatus>'Received',
  infoRequired: <FormStatus>'Information Required',
  reviewComplete: <FormStatus>'Review Complete',
  reviewOneComplete: <FormStatus>'Review One Complete',
  reviewTwoComplete: <FormStatus>'Review Two Complete',
  onHold: <FormStatus>'On Hold',
  cancelled: <FormStatus>'Cancelled',
  depositReceived: <FormStatus>'Deposit Received',
  auditorAssigned: <FormStatus>'Auditor Assigned',
  awaitingAudit: <FormStatus>'Awaiting Audit',
  awaitingExpenditureVerification: <FormStatus>'Awaiting Expenditure Verification Report',
  denied: <FormStatus>'Denied'
};

export const finalCertificationRecipientType = {
  authorizedRepresentative: {
    name: 'Authorized Representative',
    code: 'authorizedRepresentative'
  },
  irrevocableDesignee: {
    name: 'Irrevocable Designee',
    code: 'irrevocableDesignee'
  }
};
export const entFormTypes = {
  audit: {
    name: 'Audit',
    abbrev: <FormType>'audit',
    boardOrder: -1,
    requiresBusinessSignature: false
  },
  evr: { // Audit but on DM's
    name: 'Expenditure Verification Report',
    abbrev: <FormType>'evr',
    boardOrder: -1,
    requiresBusinessSignature: false
  },
  application: {
    name: 'Application',
    abbrev: <FormType>'application',
    boardOrder: 0,
    requiresBusinessSignature: true
  },
  asa: {
    name: 'Additional Season Application',
    abbrev: <FormType>'asa',
    boardOrder: -1,
    requiresBusinessSignature: true
  },
  balanceAdjustmentRequest: {
    name: 'Additional Payment Request', // formerly, 'Balance Adjustment Request'
    abbrev: <FormType>'bar',
    boardOrder: -1,
    requiresBusinessSignature: false
  },
  initialCert: {
    name: 'Initial Certification',
    abbrev: <FormType>'initialCert',
    boardOrder: -1,
    requiresBusinessSignature: false
  }
};
export const formTypes = {
  audit: {
    name: 'Audit',
    abbrev: <FormType>'audit',
    boardOrder: -1,
    requiresBusinessSignature: false
  },
  evr: { // Audit but on DMs
    name: 'Expenditure Verification Report',
    abbrev: <FormType>'evr',
    boardOrder: -1,
    requiresBusinessSignature: false
  },
  acr: {
    name: 'Annual Certification Report',
    abbrev: <FormType>'acr',
    boardOrder: -1,
    requiresBusinessSignature: true
  },
  advance: {
    name: 'Advance',
    abbrev: <FormType>'advance',
    boardOrder: -1,
    requiresBusinessSignature: true
  },
  afc: {
    name: 'Affidavit of Final Cost',
    abbrev: <FormType>'afc',
    boardOrder: -1,
    requiresBusinessSignature: true
  },
  application: {
    name: 'Application',
    abbrev: <FormType>'application',
    boardOrder: 0,
    requiresBusinessSignature: true
  },
  asa: {
    name: 'Additional Season Application',
    abbrev: <FormType>'asa',
    boardOrder: -1,
    requiresBusinessSignature: true
  },
  balanceAdjustmentRequest: {
    name: 'Additional Payment Request', // formerly, 'Balance Adjustment Request'
    abbrev: <FormType>'bar',
    boardOrder: -1,
    requiresBusinessSignature: false
  },
  changeAffiliatesOwners: {
    name: 'Change of Affiliates/Owners',
    abbrev: <FormType>'cao',
    boardOrder: 6,
    requiresBusinessSignature: true
  },
  changeCompanyName: {
    name: 'Change of Company Name Only',
    abbrev: <FormType>'ccn',
    boardOrder: 4,
    requiresBusinessSignature: true
  },
  changeLocation: {
    name: 'Change of Project Physical Location',
    abbrev: <FormType>'ccl',
    boardOrder: 5,
    requiresBusinessSignature: true
  },
  contractClosure: {
    name: 'Contract Closure',
    abbrev: <FormType>'ccc',
    boardOrder: 9,
    requiresBusinessSignature: true
  },
  coc: {
    name: 'Certificate of Compliance',
    abbrev: <FormType>'coc',
    boardOrder: -1,
    requiresBusinessSignature: true
  },
  ecr: {
    name: 'Employee Certification Report',
    abbrev: <FormType>'ecr',
    boardOrder: -1,
    requiresBusinessSignature: true
  },
  initialCert: {
    name: 'Initial Certification',
    abbrev: <FormType>'initialCert',
    boardOrder: -1,
    requiresBusinessSignature: false
  },
  extensionRequest: {
    name: 'Project Extension Request',
    abbrev: <FormType>'ext',
    boardOrder: -1,
    requiresBusinessSignature: true
  },
  fullTransferOwnership: {
    name: 'Full Transfer of Ownership to New Company',
    abbrev: <FormType>'cft',
    boardOrder: 7,
    requiresBusinessSignature: true
  },
  partialTransferOwnership: {
    name: 'Partial Transfer of Ownership to New Company',
    abbrev: <FormType>'cpt',
    boardOrder: 8,
    requiresBusinessSignature: true
  },
  pcr: {
    name: 'Project Completion Report',
    abbrev: <FormType>'pcr',
    boardOrder: -1,
    requiresBusinessSignature: true
  },
  renewalApplication: {
    name: 'Renewal Application',
    abbrev: <FormType>'rnw',
    boardOrder: 2,
    requiresBusinessSignature: true
  },
  specialRequest: {
    name: 'Special Request',
    abbrev: <FormType>'req',
    boardOrder: 10,
    requiresBusinessSignature: true
  },
  stepApplication: {
    name: 'Application (STEP)',
    abbrev: <FormType>'stepApplication',
    boardOrder: 11,
    requiresBusinessSignature: true
  },
};
export const defaultEditableFormStatuses = [
  formStatus.pending,
  formStatus.infoRequired
];

export const editableFormStatuses = {
  [formTypes.audit.abbrev]: {
    [userType.applicant.code]: [
      formStatus.pending,
      formStatus.infoRequired,
      formStatus.depositReceived,
      formStatus.auditorAssigned,
      formStatus.awaitingAudit,
      formStatus.awaitingExpenditureVerification,
    ],
    [userType.management.code]: [
      formStatus.pending,
      formStatus.infoRequired,
      formStatus.depositReceived,
      formStatus.auditorAssigned,
      formStatus.awaitingAudit,
      formStatus.awaitingExpenditureVerification,
      formStatus.received
    ],
    [userType.auditor.code]: [
      formStatus.auditorAssigned,
      formStatus.awaitingAudit,
      formStatus.awaitingExpenditureVerification
    ]
  },
  [formTypes.evr.abbrev]: {
    [userType.applicant.code]: [
      formStatus.pending,
      formStatus.depositReceived,
      formStatus.infoRequired,
      formStatus.auditorAssigned,
      formStatus.awaitingExpenditureVerification,
    ],
    [userType.management.code]: [
      formStatus.pending,
      formStatus.infoRequired,
      formStatus.depositReceived,
      formStatus.auditorAssigned,
      formStatus.awaitingExpenditureVerification,
      formStatus.received
    ],
    [userType.auditor.code]: [
      formStatus.auditorAssigned,
      formStatus.awaitingAudit,
      formStatus.awaitingExpenditureVerification
    ]
  }
};

export const formActionNames: { [k: string]: string } = {
  addENTId: 'add ent id',
  close: 'close',
  'no-sign': 'i do not wish to sign',
  pay: 'pay',
  print: 'print',
  'set-received': 'set received form status',
  'request-payment': 'send for payment',
  'request-signature': 'send for signature',
  save: 'save',
  'save-credits': 'save',
  sign: 'sign',
  submit: 'submit',
  submitCostReport: 'Submit Cost Report',
  'complete-review': 'Review Complete',
  unlock: 'unlock for editing',
  completeAudit: 'Complete Audit',
  createInitialCertLetter: 'Send Letter',
  resendInitialCertLetter: 'Resend Letter',
  createFinalCertAttestation: 'Send Attestation',
  createFinalCertLetter: 'Send Letter',
  sendIrrevocableDesigneeAgreement: 'Send Irrevocable Designee'
};

export const managerEditableForms: FormType[] = [
  formTypes.contractClosure.abbrev,
  formTypes.specialRequest.abbrev,
  formTypes.balanceAdjustmentRequest.abbrev
];

export const oneToOneFormTypes: FormType[] = [
  formTypes.advance.abbrev,
  formTypes.application.abbrev,
  formTypes.renewalApplication.abbrev
];

export const oneToManyFormTypes: FormType[] = [
  formTypes.acr.abbrev,
  formTypes.afc.abbrev,
  formTypes.audit.abbrev,
  formTypes.evr.abbrev,
  formTypes.changeAffiliatesOwners.abbrev,
  formTypes.contractClosure.abbrev,
  formTypes.changeLocation.abbrev,
  formTypes.changeCompanyName.abbrev,
  formTypes.fullTransferOwnership.abbrev,
  formTypes.initialCert.abbrev,
  formTypes.partialTransferOwnership.abbrev,
  formTypes.ecr.abbrev,
  formTypes.extensionRequest.abbrev,
  formTypes.pcr.abbrev,
  formTypes.specialRequest.abbrev,
  formTypes.balanceAdjustmentRequest.abbrev,
  formTypes.asa.abbrev,
  formTypes.initialCert.abbrev,
  formTypes.coc.abbrev,
  formTypes.stepApplication.abbrev
];

export function getEditableFormStatuses(user: string, formType: string) {
  const pendingStatusesPerFormType = editableFormStatuses[formType];
  const pendingStatuses = pendingStatusesPerFormType
    ? pendingStatusesPerFormType[user]
    : [];
  return pendingStatuses && pendingStatuses.length > 0
    ? pendingStatuses
    : defaultEditableFormStatuses;
}

export function signableFormStatuses(
  user: string,
  formType: string
): FormStatus[] {
  return getEditableFormStatuses(user, formType).concat(
    formStatuses.pendingSignature.name
  );
}

export const formCertificationStatus = {
  certified: {
    display: 'Certified',
    value: 'certified'
  }
};

export const templateTypes = {
  regular: {
    name: 'Regular Template',
    code: 'regular'
  },
  superCert: {
    name: 'Scripted Episodic Template',
    code: 'superCert'
  }
};
