<div style="margin:-20px;" class="wow zoomIn">
  <img src="assets/images/landing/landing1.png" class="img-responsive"/>
</div>

<section id="features" class="services mt-4 ">
  <div class="row justify-content-center">

    <div class="col-sm-3">
      <h2 class="text-blue"><b>Applicants</b></h2>

      <h5 class="text-muted">To apply for a Louisiana Economic Development Incentive, create an account or log in. </h5>

      <p>
        <a [routerLink]="['/register']" class="btn btn-teal"> <i class="fa fa-user-plus pr-2"></i>Create an Account</a>
        &nbsp;
        <a [routerLink]="['/login']" class="btn btn-teal"> <i class="fa fa-sign-in pr-2"></i>Log In</a>
      </p>
      <span>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
        </p>
        <a href="https://storage.googleapis.com/fastlane-public-files/PDFExample.pdf" target="_blank">Eligibility Guidelines</a>
      </span>
    </div>

    <div class="col-sm-3">
      <h2 class="text-blue"><b>External Reviewers</b></h2>
      <h5 class="text-muted">FASTLANE allows state-wide interoperability and Board of Commerce and Industry
        access. </h5>
      <p>
        <a [routerLink]="['/login']" class="btn btn-teal"> <i class="fa fa-sign-in pr-2"></i>Log In</a>
      </p>


    </div>
    <div class="col-sm-3">
      <h2 class="text-blue"><b>Managers</b></h2>
      <h5 class="text-muted">LED Managers are responsible for processing all incentive forms and project tracking. </h5>
      <p>
        <a [routerLink]="['/login']" class="btn btn-teal"> <i class="fa fa-sign-in pr-2"></i>Log In</a>
      </p>
    </div>
  </div>
</section>

<div class="LEDBlue_bg mt-4" style="margin-left:-20px; margin-right:-20px;">
  <div class="row m-4">
    <div class="col-sm-6 text-white mt-4"><br>
      <h1><b>What is FASTLANE?</b></h1>
      <p>FASTLANE Next Generation (FASTLANE) is the web-based system that manages Louisiana’s business incentive
        programs that are approved by the Board of Commerce and Industry. The FASTLANE website is a secure online
        interface that allows applicants to electronically send data directly to Louisiana Economic Development. It is
        designed for individual applicants managing one company or consultants who manage multiple companies’ data. </p>

    </div>
    <div class="col-sm-6 text-white mt-4"><br>
      <h1><b>Available Incentives</b></h1>
      <p>The following Business and Entertainment Incentives are available: </p>
      <ul>
        <li>Enterprise Zone</li>
        <li>Industrial Tax Exemption</li>
        <li>Quality Jobs</li>
        <li>Restoration Tax Abatement</li>
        <li>Digital Interactive Media and Software Tax Credit</li>
        <li>Motion Picture Production Tax Credit</li>
        <li>State Trade Expansion Program</li>
      </ul>

     <br><br>

    </div>

  </div>

</div>

<div>
  <section class="container features ">
    <div class="row pt-4">
      <div class="col-lg-12 text-center">
        <div class="navy-line"></div>

        <h1>State-wide interoperablity.</h1>


        <h5>FASTLANE enables State Agencies and Local Governing Authorities to access incentive project data. </h5>

        <br><br>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 text-center wow fadeInLeft">
        <div>
          <img src="assets/images/landing/laparish.png" width="200px"/>
          <h4>Parish Assessors</h4>
          <p>Parish Assessors can access investment data which is used to correctly assess the value of a project in the
            parish. </p>
        </div>
        <div class="m-t-lg">
          <img src="assets/images/landing/lwc_logo.png" width="200px" class="pb-4"/>
          <h4>Louisiana Workforce Commission</h4>
          <p>The Louisiana Workforce Commission can access project data to verify that applicants are identified and in
            good standing with the State of Louisiana. </p>
        </div>
      </div>

      <div class="col-md-6 text-center wow fadeInRight">
        <div>
          <img src="assets/images/landing/ldrseal.png" width="150px"/>

          <h4>Louisiana Department of Revenue</h4>

          <p>The Louisiana Department of Revenue can access project data to verify that applicants are current and in
            good financial standing with the State of Louisiana. </p>
        </div>

        <div class="m-t-lg">
          <img src="assets/images/landing/lga.png" width="200px"/>

          <h4>Local Governing Authorities</h4>

          <p>Local Governing Authorities can access project data to verify that applicants are in good standing and give
            approval for projects within their area. </p>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="LEDBlue_bg mt-4 " style="margin-left:-20px; margin-right:-20px; margin-bottom:-20px;">
  <section class="container features ">
    <div class="row text-white">
      <div class="col-12 mt-4 text-center">
        <h3>Need Assistance?</h3>

        <p>For assistance contact Louisiana Economic Development at (225)342-3000.</p>

           <p><a [routerLink]="['/help']" class="btn btn-teal btn-lg"> <i class="fa fa-question-circle pr-2"></i>FASTLANE
          Help Center</a></p><br>
      </div>
    </div>
  </section>
</div>


