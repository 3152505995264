import {
  FormType,
  formTypes,
  formStatuses,
  entFormStatuses,
  FormStatus,
    dmFormStatuses, stepFormStatuses
} from '../form/form.constants';
import { ProjectDate } from './models/project-date.model';
import { ProjectForm } from './models/form.model';
import { InitialCertification } from '../entertainment/models/film/initial-certification.model';
import { InitialCertification as DigitalInitialCertification } from '../entertainment/models/digital/initial-certification.model';
import { FormIdentifier } from './models/form-identifier.model';
import { addYears, addMonths, addDays } from '../shared/shared.functions';
import { Audit as DigitalAudit } from '../entertainment/models/digital/audit.model';
import { Audit } from '../entertainment/models/film/audit.model';
import { Advance } from './models/advance.model';
import { CertificiateComplianceForm } from '../incentive/compliance/models/certificate-of-compliance.model'
import { ProjectCompletionReport } from '../incentive/compliance/models/project-completion-report.model';
import { ProjectInfoBase } from './models/project-info-base.model';
declare var moment: any;

export const QJ = 'QJ';
export const RTA = 'RTA';
export const ITE = 'ITE';
export const EZ = 'EZ';
export const FILM = 'FILM';
export const DM = 'DM';
export const STEP = 'STEP';

export const actionButtons = {
  pencil: { name: 'pencil' },
  trash: { name: 'trash' },
  usd: { name: 'usd' },
  unlock: { name: 'unlock' }
};

export const boardStatus = {
  approved: 'Approved',
  deferred: 'Deferred',
  denied: 'Denied',
  ready: 'Ready'
};

export const contactType = {
  business: {
    code: 'Business'
  },
  consultant: {
    code: 'Consultant'
  },
  signee: {
    code: 'Business Signatory'
  }
};

export const externalStatus = {
  noObjection: 'No Objection',
  objection: 'Objection',
  objectionLifted: 'Objection Lifted',
  pending: 'Pending Review'
};

export const publicReportTypes = {
  advance: {
    reportName: 'Advance Notification',
    abbrev: 'advance'
  },
  projects: {
    reportName: 'Board Approved Projects',
    abbrev: 'project'
  }
};

export const publicBoardApprovedReports = {
  ez: {
    reportName: 'Enterprise Zone',
    abbrev: 'EZ'
  },
  ite: {
    reportName: 'Industrial Tax Exemption',
    abbrev: 'ITE'
  },
  rta: {
    reportName: 'Restoration Tax Abatement',
    abbrev: 'RTA'
  },
  qj: {
    reportName: 'Quality Jobs',
    abbrev: 'QJ'
  }
};

export const dateTypes = {
  advanceExpirationDate: {
    name: 'Advance Expiration Date',
    form: <FormType>'advance',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
  applicationDueDate: {
    name: 'Application Due Date',
    form: <FormType>'application',
    isDueDate: true,
    isFormDate: false,
    category: 'Due Date'
  },
  actualExpenditureStartDate: {
    name: 'Actual Expenditure Start Date',
    form: <FormType>'audit',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  actualExpenditureEndDate: {
    name: 'Actual Expenditure End Date',
    form: <FormType>'audit',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  auditDate: {
    name: 'Audit Date',
    form: <FormType>'audit',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  closureDate: {
    name: 'Closure Date',
    form: <FormType>'audit',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  costReportReceivedDate: {
    name: 'Cost Report Received Date',
    form: <FormType>'audit',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  costReportSubmissionDate: {
    name: 'Estimated Cost Report Submission Date',
    form: <FormType>'initialCert',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  postProductionCostReportDate: {
    name: 'Estimated Post Production Cost Report Submission Date',
    form: <FormType>'initialCert',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  photographyStartDueDate: {
    name: 'Principal Photography Start Due Date',
    form: <FormType>'initialCert',
    isDueDate: true,
    isFormDate: true,
    category: 'Form Date'
  },
  costReportFinalEligibleDate: {
    name: 'Final Eligible Date for Cost Report',
    form: <FormType>'initialCert',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  proofofPrincipalPhotographyReceivedDate: {
    name: 'Proof of Principal Photography Received Date',
    form: <FormType>'initialCert',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  eligibleExpenditureStartDate: {
    name: 'Eligible Expenditure Period - Start Date',
    form: <FormType>'initialCert',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  eligibleExpenditureEndDate: {
    name: 'Eligible Expenditure Period - End Date',
    form: <FormType>'initialCert',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  finalCertIssuanceDate: {
    name: 'Final Certification Issuance Date',
    form: <FormType>'audit',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  initialCertIssuanceDate: {
    name: 'Initial Certification Issuance Date',
    form: <FormType>'initialCert',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  inspectionDate: {
    name: 'Inspection Date',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
  relatedApplicationReceivedDate: {
    name: 'Related Application Received Date',
    form: <FormType>'initialCert',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  contractEffectiveDate: {
    name: 'Contract Effective Date',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
  contractExpirationDate: {
    name: 'Contract Expiration Date',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
  contractExecutedDate: {
    name: 'Contract Executed Date',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
  pcrDueDate: {
    name: 'PCR Due Date',
    form: <FormType>'pcr',
    isDueDate: true,
    isFormDate: false,
    category: 'Due Date'
  },
  afcDueDate: {
    name: 'AFC Due Date',
    form: <FormType>'afc',
    isDueDate: true,
    isFormDate: false,
    category: 'Due Date'
  },
  acrDueDate: {
    name: 'ACR Due Date',
    form: <FormType>'acr',
    isDueDate: true,
    isFormDate: false,
    category: 'Due Date'
  },
  ecrDueDate: {
    name: 'ECR Due Date',
    form: <FormType>'ecr',
    isDueDate: true,
    isFormDate: false,
    category: 'Due Date'
  },
  renewalApplicationDueDate: {
    name: 'Renewal Application Due Date',
    form: <FormType>'rnw',
    isDueDate: true,
    isFormDate: false,
    category: 'Due Date'
  },
  renewalApplicationEligibilityDate: {
    name: 'Renewal Application Eligibility Date',
    form: <FormType>'rnw',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
  renewalEffectiveDate: {
    name: 'Renewal Effective Date',
    isDueDate: false,
    isFormDate: false,
    category: 'Due Date'
  },
  renewalExpirationDate: {
    name: 'Renewal Expiration Date',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
  renewalExecutedDate: {
    name: 'Renewal Executed Date',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
  contractClosureDate: {
    name: 'Contract Closure Date',
    form: <FormType>'ccc',
    isDueDate: false,
    isFormDate: false,
    category: 'Due Date'
  },
  ParentProjectAdvanceReceivedDate: {
    name: 'Parent Project Advance Received Date',
    form: <FormType>'advance',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
  ParentProjectApplicationReceivedDate: {
    name: 'Parent Project Application Received Date',
    form: <FormType>'application',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
  verificationReportReceivedDate: {
    name: 'Verification Report Received Date',
    form: <FormType>'audit',
    isDueDate: false,
    isFormDate: true,
    category: 'Form Date'
  },
  stepStartDate: {
    name: 'Start Date',
    form: <FormType>'stepApplication',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
  stepEndDate: {
    name: 'End Date',
    form: <FormType>'stepApplication',
    isDueDate: false,
    isFormDate: false,
    category: 'Important Date'
  },
};

export const defaultBiProjectDatesArray = [
  dateTypes.advanceExpirationDate,
  dateTypes.applicationDueDate,
  dateTypes.contractEffectiveDate,
  dateTypes.contractExpirationDate,
  dateTypes.contractExecutedDate,
  dateTypes.pcrDueDate,
  dateTypes.afcDueDate,
  dateTypes.acrDueDate,
  dateTypes.ecrDueDate,
  dateTypes.inspectionDate,
  dateTypes.renewalApplicationDueDate,
  dateTypes.renewalEffectiveDate,
  dateTypes.renewalExpirationDate,
  dateTypes.renewalExecutedDate,
  dateTypes.contractClosureDate,
  dateTypes.ParentProjectAdvanceReceivedDate,
  dateTypes.ParentProjectApplicationReceivedDate,
  dateTypes.renewalApplicationEligibilityDate
];

export const defaultFilmProjectDatesArray = [
  dateTypes.relatedApplicationReceivedDate
];

export const defaultStepProjectDatesArray = [
  dateTypes.stepStartDate,
  dateTypes.stepEndDate
];

export const defaultProjectDatesArrayMap = {
  [QJ]: defaultBiProjectDatesArray,
  [EZ]: defaultBiProjectDatesArray,
  [RTA]: defaultBiProjectDatesArray,
  [ITE]: defaultBiProjectDatesArray,
  [FILM]: defaultFilmProjectDatesArray,
  [STEP]: defaultStepProjectDatesArray
};

export const filmInitialCertDateTypes = [
  dateTypes.relatedApplicationReceivedDate,
  dateTypes.initialCertIssuanceDate,
  dateTypes.eligibleExpenditureStartDate,
  dateTypes.eligibleExpenditureEndDate,
  dateTypes.costReportSubmissionDate,
  dateTypes.postProductionCostReportDate,
  dateTypes.costReportFinalEligibleDate,
  dateTypes.photographyStartDueDate,
  dateTypes.proofofPrincipalPhotographyReceivedDate
];

// TODO: Update film audit date types.
export const filmAuditDateTypes = [
  dateTypes.actualExpenditureStartDate,
  dateTypes.actualExpenditureEndDate,
  dateTypes.auditDate,
  dateTypes.finalCertIssuanceDate,
  dateTypes.closureDate,
  dateTypes.costReportReceivedDate,
  dateTypes.verificationReportReceivedDate
];

export const dmInitialCertDateTypes = [
  dateTypes.relatedApplicationReceivedDate,
  dateTypes.initialCertIssuanceDate,
  dateTypes.costReportSubmissionDate
];
export const dmAuditDateTypes = [
  dateTypes.actualExpenditureStartDate,
  dateTypes.actualExpenditureEndDate,
  dateTypes.finalCertIssuanceDate,
  dateTypes.auditDate,
  dateTypes.closureDate
];
export type dateTypes = [
  'Advance Expiration Date',
  'Application Due Date',
  'Contract Effective Date',
  'Contract Expiration Date',
  'Contract Executed Date',
  'PCR Due Date',
  'AFC Due Date',
  'ACR Due Date',
  'ECR Due Date',
  'Renewal Application Due Date',
  'Renewal Application Eligibility Date',
  'Renewal Effective Date',
  'Renewal Expiration Date',
  'Renewal Executed Date',
  'Contract Closure Date',
  'Parent Project Advance Received Date',
  'Parent Project Application Received Date'
];

export const deadlineDates = [
  dateTypes.applicationDueDate.name,
  dateTypes.contractExpirationDate.name,
  dateTypes.renewalApplicationDueDate.name,
  dateTypes.renewalExpirationDate.name,
  dateTypes.acrDueDate.name,
  dateTypes.pcrDueDate.name,
  dateTypes.afcDueDate.name,
  dateTypes.ecrDueDate.name
];

export const dateTypeMap = [
  {
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    dateTypes: filmInitialCertDateTypes
  },
  {
    incentiveProgram: FILM,
    formType: formTypes.audit.abbrev,
    dateTypes: filmAuditDateTypes
  },
  {
    incentiveProgram: DM,
    formType: formTypes.initialCert.abbrev,
    dateTypes: dmInitialCertDateTypes
  },
  {
    incentiveProgram: DM,
    formType: formTypes.audit.abbrev,
    dateTypes: dmAuditDateTypes
  }
];
export const expenditureActions = {
  laExpenditure: 'laExpenditure',
  laVfx: 'laVisualEffectsExpenditure',
  laResidentPayroll: 'residentPayroll'
};
export const vfxActions = {
  vfxinLAChaged: 'vfxinLAChaged'
};

export const companyLlcActions = {
  isLlc: 'isLlc'
};
export const auditDepositTransactionActions = {
  requestPayment: 'Request Payment',
  requestRefund: 'Request Refund',
  OutgoingpaymentRequest: 'OutgoingpaymentRequest',
  OutgoingpaymentCancelled: 'OutgoingpaymentCancelled'
};
export const updateFormDateActions = {
  initCertTemplateChanged: 'initialCertTemplateChanged',
  costReportSubmissionDateChanged: 'costReportSubmissionDateChanged',
  principalPhotographyStartDateChanged: 'principalPhotographyStartDateChanged',
  auditPeriodChanged: 'auditPeriodChanged',
  relatedApplicationReceivedDateChanged:
    'relatedApplicationReceivedDateChanged',
  initialCertIssuanceDateChanged: 'initialCertIssuanceDateChanged',
  eligibleExpenditureStartDateChanged: 'eligibleExpenditureStartDateChanged',
  eligibleExpenditureEndDateChanged: 'eligibleExpenditureEndDateChanged',
  costReportFinalEligibleDateChanged: 'costReportFinalEligibleDateChanged',
  principalPhotographyStartDueDateChanged:
    'principalPhotographyStartDueDateChanged',
  postProductionCostReportDateChanged: 'postProductionCostReportDateChanged',
  actualExpenditureStartDateChanged: 'actualExpenditureStartDateChanged',
  actualExpenditureEndDateChanged: 'actualExpenditureEndDateChanged',
  auditDateChanged: 'auditDateChanged',
  finalCertIssuanceDateChanged: 'finalCertIssuanceDateChanged',
  closureDateChanged: 'closureDateChanged',
  costReportReceivedDateChanged: 'costReportReceivedDateChanged',
  verificationReportReceivedDateChanged:
    'verificationReportReceivedDateChanged',
  proofofPrincipalPhotographyReceivedDateChanged:
    'proofofPrincipalPhotographyReceivedDateChanged',
  cocExtensionRequested: 'cocExtensionRequested',
  stepReviewComplete: 'stepReviewComplete',
};

export const programChangedActions = {
  programChanged: 'programChanged'
};
export const formDateActionMap = [
  {
    formDateAction: updateFormDateActions.initCertTemplateChanged,
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = form as InitialCertification;
      const issuanceDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.initialCertIssuanceDate.name &&
          pd.formId.formType === initCert.type &&
          pd.formId.formIndex === initCert.formIndex
      );
      const eligibleExpenditureEndDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.eligibleExpenditureEndDate.name &&
          pd.formId.formType === initCert.type &&
          pd.formId.formIndex === initCert.formIndex
      );
      const costReportFinalEligibleDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.costReportFinalEligibleDate.name &&
          pd.formId.formType === initCert.type &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (issuanceDateIndex === -1) {
        return;
      }
      if (initCert.template === 'regular') {
        // later change the strings to constants
        if (eligibleExpenditureEndDateIndex >= 0) {
          // Update dates
          projectDates[eligibleExpenditureEndDateIndex].date = updateDate(
            projectDates,
            issuanceDateIndex,
            2
          );
          initCert.initialCertCriticalDates.eligibleExpenditureEndDate = updateDate(
            projectDates,
            issuanceDateIndex,
            2
          );
        } else {
          // create dates
          const newDate = new ProjectDate();
          newDate.type = dateTypes.eligibleExpenditureEndDate.name;
          newDate.category = dateTypes.eligibleExpenditureEndDate.category;
          newDate.formId = new FormIdentifier();
          newDate.formId.projectGuid = projectId;
          newDate.formId.formIndex = form.formIndex;
          newDate.formId.formType = form.type;
          newDate.created = new Date();
          newDate.date = updateDate(projectDates, issuanceDateIndex, 2);
          projectDates.push(newDate);
          initCert.initialCertCriticalDates.eligibleExpenditureEndDate =
            newDate.date;
        }
      } else if (initCert.template === 'superCert') {
        if (eligibleExpenditureEndDateIndex >= 0) {
          // Update dates
          projectDates[eligibleExpenditureEndDateIndex].date = updateDate(
            projectDates,
            issuanceDateIndex,
            5
          );
          // Update Form Dates
          initCert.initialCertCriticalDates.eligibleExpenditureEndDate = updateDate(
            projectDates,
            issuanceDateIndex,
            5
          );
        } else {
          // create dates
          const newDate = new ProjectDate();
          newDate.type = dateTypes.eligibleExpenditureEndDate.name;
          newDate.category = dateTypes.eligibleExpenditureEndDate.category;
          newDate.formId = new FormIdentifier();
          newDate.formId.projectGuid = projectId;
          newDate.formId.formIndex = form.formIndex;
          newDate.formId.formType = form.type;
          newDate.created = new Date();
          newDate.date = updateDate(projectDates, issuanceDateIndex, 5);
          projectDates.push(newDate);
          // Update Form Dates
          initCert.initialCertCriticalDates.eligibleExpenditureEndDate =
            newDate.date;
        }
      }
      const updatedEligibleEndDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.eligibleExpenditureEndDate.name &&
          pd.formId.formType === initCert.type &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (updatedEligibleEndDateIndex >= 0) {
        if (costReportFinalEligibleDateIndex >= 0) {
          projectDates[costReportFinalEligibleDateIndex].date = updateDate(
            projectDates,
            updatedEligibleEndDateIndex,
            0,
            6
          );
          // Update Form Dates
          initCert.initialCertCriticalDates.costReportFinalEligibleDate = updateDate(
            projectDates,
            updatedEligibleEndDateIndex,
            0,
            6
          );
        } else {
          // create dates
          const newDate = new ProjectDate();
          newDate.type = dateTypes.costReportFinalEligibleDate.name;
          newDate.category = dateTypes.costReportFinalEligibleDate.category;
          newDate.formId = new FormIdentifier();
          newDate.formId.projectGuid = projectId;
          newDate.formId.formIndex = form.formIndex;
          newDate.formId.formType = form.type;
          newDate.created = new Date();
          newDate.date = updateDate(
            projectDates,
            updatedEligibleEndDateIndex,
            0,
            6
          );
          projectDates.push(newDate);
          // Update Form Dates
          initCert.initialCertCriticalDates.costReportFinalEligibleDate =
            newDate.date;
        }
      }
    }
  },
  {
    formDateAction: updateFormDateActions.costReportSubmissionDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <InitialCertification>form;
      if (!initCert.estimatedDates.costReportSubmissionDate) {
        return;
      }
      const costreportSubmissionDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.costReportSubmissionDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (costreportSubmissionDateIndex >= 0) {
        projectDates[costreportSubmissionDateIndex].date =
          initCert.estimatedDates.costReportSubmissionDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.costReportSubmissionDate.name;
        newDate.category = dateTypes.costReportSubmissionDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = initCert.estimatedDates.costReportSubmissionDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.principalPhotographyStartDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <InitialCertification>form;
      if (!initCert.estimatedDates.photographyStartDate) {
        return;
      }
      const photographyStartDueDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.photographyStartDueDate.name
      );
      if (photographyStartDueDateIndex >= 0) {
        projectDates[photographyStartDueDateIndex].date = addMonths(
          new Date(initCert.estimatedDates.photographyStartDate),
          1
        );
        initCert.initialCertCriticalDates.principalPhotographyStartDueDate =
          projectDates[photographyStartDueDateIndex].date;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.photographyStartDueDate.name;
        newDate.category = dateTypes.photographyStartDueDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = addMonths(
          new Date(initCert.estimatedDates.photographyStartDate),
          1
        );
        projectDates.push(newDate);
        initCert.initialCertCriticalDates.principalPhotographyStartDueDate =
          newDate.date;
      }
    }
  },
  {
    formDateAction: updateFormDateActions.auditPeriodChanged,
    incentiveProgram: DM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectGuid: string,
      data: any
    ) => {
      const audit = <DigitalAudit>form;
      if (!audit.auditPeriod) {
        return;
      }
      if (audit.auditPeriod[0]) {
        const actualExpenditureStartDateIndex = projectDates.findIndex(
          pd =>
            pd.type === dateTypes.actualExpenditureStartDate.name &&
            pd.formId.formIndex === form.formIndex
        );
        if (actualExpenditureStartDateIndex >= 0) {
          projectDates[actualExpenditureStartDateIndex].date =
            audit.auditPeriod[0];
        } else {
          const newDate = new ProjectDate();
          newDate.type = dateTypes.actualExpenditureStartDate.name;
          newDate.category = dateTypes.actualExpenditureStartDate.category;
          newDate.formId = new FormIdentifier();
          newDate.formId.projectGuid = projectGuid;
          newDate.formId.formIndex = form.formIndex;
          newDate.formId.formType = form.type;
          newDate.created = new Date();
          newDate.date = audit.auditPeriod[0];
          projectDates.push(newDate);
        }
      }
      if (audit.auditPeriod[1]) {
        const actualExpenditureEndDateIndex = projectDates.findIndex(
          pd =>
            pd.type === dateTypes.actualExpenditureEndDate.name &&
            pd.formId.formIndex === form.formIndex
        );
        if (actualExpenditureEndDateIndex >= 0) {
          projectDates[actualExpenditureEndDateIndex].date =
            audit.auditPeriod[1];
        } else {
          const newDate = new ProjectDate();
          newDate.type = dateTypes.actualExpenditureEndDate.name;
          newDate.category = dateTypes.actualExpenditureEndDate.category;
          newDate.formId = new FormIdentifier();
          newDate.formId.projectGuid = projectGuid;
          newDate.formId.formIndex = form.formIndex;
          newDate.formId.formType = form.type;
          newDate.created = new Date();
          newDate.date = audit.auditPeriod[1];
          projectDates.push(newDate);
        }
      }
    }
  },
  {
    formDateAction: updateFormDateActions.relatedApplicationReceivedDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <InitialCertification>form;
      if (!initCert.initialCertCriticalDates.relatedApplicationReceivedDate) {
        return;
      }
      const relatedApplicationReceivedDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.relatedApplicationReceivedDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (relatedApplicationReceivedDateIndex >= 0) {
        projectDates[relatedApplicationReceivedDateIndex].date =
          initCert.initialCertCriticalDates.relatedApplicationReceivedDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.relatedApplicationReceivedDate.name;
        newDate.category = dateTypes.relatedApplicationReceivedDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date =
          initCert.initialCertCriticalDates.relatedApplicationReceivedDate;
        projectDates.push(newDate);
      }
      // Also update eligible expenditure start date.
      const updatedRelatedApplicationReceivedDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.relatedApplicationReceivedDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      const eligibleExpenditureStartDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.eligibleExpenditureStartDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (eligibleExpenditureStartDateIndex >= 0) {
        projectDates[eligibleExpenditureStartDateIndex].date = updateDate(
          projectDates,
          updatedRelatedApplicationReceivedDateIndex,
          -1,
          0
        );
        initCert.initialCertCriticalDates.eligibleExpenditureStartDate = updateDate(
          projectDates,
          updatedRelatedApplicationReceivedDateIndex,
          -1,
          0
        );
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.eligibleExpenditureStartDate.name;
        newDate.category = dateTypes.eligibleExpenditureStartDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = updateDate(
          projectDates,
          updatedRelatedApplicationReceivedDateIndex,
          -1,
          0
        );
        projectDates.push(newDate);
        initCert.initialCertCriticalDates.eligibleExpenditureStartDate =
          newDate.date;
      }
    }
  },
  {
    formDateAction: updateFormDateActions.initialCertIssuanceDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <InitialCertification>form;
      if (!initCert.initialCertCriticalDates.initialCertIssuanceDate) {
        return;
      }
      const initialCertIssuanceDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.initialCertIssuanceDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (initialCertIssuanceDateIndex >= 0) {
        projectDates[initialCertIssuanceDateIndex].date =
          initCert.initialCertCriticalDates.initialCertIssuanceDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.initialCertIssuanceDate.name;
        newDate.category = dateTypes.initialCertIssuanceDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date =
          initCert.initialCertCriticalDates.initialCertIssuanceDate;
        projectDates.push(newDate);
      }
      // Update other dates which depend on issuance date.
      const updatedInitialCertIssuanceDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.initialCertIssuanceDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      const eligibleExpenditureEndDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.eligibleExpenditureEndDate.name &&
          pd.formId.formType === initCert.type &&
          pd.formId.formIndex === initCert.formIndex
      );
      const costReportFinalEligibleDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.costReportFinalEligibleDate.name &&
          pd.formId.formType === initCert.type &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (initCert.template === 'regular') {
        // later change the strings to constants
        if (eligibleExpenditureEndDateIndex >= 0) {
          // Update dates
          projectDates[eligibleExpenditureEndDateIndex].date = updateDate(
            projectDates,
            updatedInitialCertIssuanceDateIndex,
            2
          );
          initCert.initialCertCriticalDates.eligibleExpenditureEndDate = updateDate(
            projectDates,
            updatedInitialCertIssuanceDateIndex,
            2
          );
        } else {
          // create dates
          const newDate = new ProjectDate();
          newDate.type = dateTypes.eligibleExpenditureEndDate.name;
          newDate.category = dateTypes.eligibleExpenditureEndDate.category;
          newDate.formId = new FormIdentifier();
          newDate.formId.projectGuid = projectId;
          newDate.formId.formIndex = form.formIndex;
          newDate.formId.formType = form.type;
          newDate.created = new Date();
          newDate.date = updateDate(
            projectDates,
            updatedInitialCertIssuanceDateIndex,
            2
          );
          projectDates.push(newDate);
          initCert.initialCertCriticalDates.eligibleExpenditureEndDate =
            newDate.date;
        }
      } else if (initCert.template === 'superCert') {
        if (eligibleExpenditureEndDateIndex >= 0) {
          // Update dates
          projectDates[eligibleExpenditureEndDateIndex].date = updateDate(
            projectDates,
            updatedInitialCertIssuanceDateIndex,
            5
          );
          // Update Form Dates
          initCert.initialCertCriticalDates.eligibleExpenditureEndDate = updateDate(
            projectDates,
            updatedInitialCertIssuanceDateIndex,
            5
          );
        } else {
          // create dates
          const newDate = new ProjectDate();
          newDate.type = dateTypes.eligibleExpenditureEndDate.name;
          newDate.category = dateTypes.eligibleExpenditureEndDate.category;
          newDate.formId = new FormIdentifier();
          newDate.formId.projectGuid = projectId;
          newDate.formId.formIndex = form.formIndex;
          newDate.formId.formType = form.type;
          newDate.created = new Date();
          newDate.date = updateDate(
            projectDates,
            updatedInitialCertIssuanceDateIndex,
            5
          );
          projectDates.push(newDate);
          // Update Form Dates
          initCert.initialCertCriticalDates.eligibleExpenditureEndDate =
            newDate.date;
        }
      }
      const updatedEligibleEndDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.eligibleExpenditureEndDate.name &&
          pd.formId.formType === initCert.type &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (updatedEligibleEndDateIndex >= 0) {
        if (costReportFinalEligibleDateIndex >= 0) {
          projectDates[costReportFinalEligibleDateIndex].date = updateDate(
            projectDates,
            updatedEligibleEndDateIndex,
            0,
            6
          );
          // Update Form Dates
          initCert.initialCertCriticalDates.costReportFinalEligibleDate = updateDate(
            projectDates,
            updatedEligibleEndDateIndex,
            0,
            6
          );
        } else {
          // create dates
          const newDate = new ProjectDate();
          newDate.type = dateTypes.costReportFinalEligibleDate.name;
          newDate.category = dateTypes.costReportFinalEligibleDate.category;
          newDate.formId = new FormIdentifier();
          newDate.formId.projectGuid = projectId;
          newDate.formId.formIndex = form.formIndex;
          newDate.formId.formType = form.type;
          newDate.created = new Date();
          newDate.date = updateDate(
            projectDates,
            updatedEligibleEndDateIndex,
            0,
            6
          );
          projectDates.push(newDate);
          // Update Form Dates
          initCert.initialCertCriticalDates.costReportFinalEligibleDate =
            newDate.date;
        }
      }
    }
  },
  {
    formDateAction: updateFormDateActions.eligibleExpenditureStartDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <InitialCertification>form;
      if (!initCert.initialCertCriticalDates.eligibleExpenditureStartDate) {
        return;
      }
      const eligibleExpenditureStartDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.eligibleExpenditureStartDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (eligibleExpenditureStartDateIndex >= 0) {
        projectDates[eligibleExpenditureStartDateIndex].date =
          initCert.initialCertCriticalDates.eligibleExpenditureStartDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.eligibleExpenditureStartDate.name;
        newDate.category = dateTypes.eligibleExpenditureStartDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date =
          initCert.initialCertCriticalDates.eligibleExpenditureStartDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.eligibleExpenditureEndDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <InitialCertification>form;
      if (!initCert.initialCertCriticalDates.eligibleExpenditureEndDate) {
        return;
      }
      const eligibleExpenditureEndDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.eligibleExpenditureEndDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (eligibleExpenditureEndDateIndex >= 0) {
        projectDates[eligibleExpenditureEndDateIndex].date =
          initCert.initialCertCriticalDates.eligibleExpenditureEndDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.eligibleExpenditureEndDate.name;
        newDate.category = dateTypes.eligibleExpenditureEndDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date =
          initCert.initialCertCriticalDates.eligibleExpenditureEndDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.costReportFinalEligibleDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <InitialCertification>form;
      if (!initCert.initialCertCriticalDates.costReportFinalEligibleDate) {
        return;
      }
      const costReportFinalEligibleDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.costReportFinalEligibleDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (costReportFinalEligibleDateIndex >= 0) {
        projectDates[costReportFinalEligibleDateIndex].date =
          initCert.initialCertCriticalDates.costReportFinalEligibleDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.costReportFinalEligibleDate.name;
        newDate.category = dateTypes.costReportFinalEligibleDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date =
          initCert.initialCertCriticalDates.costReportFinalEligibleDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction:
      updateFormDateActions.proofofPrincipalPhotographyReceivedDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <InitialCertification>form;
      if (
        !initCert.initialCertCriticalDates
          .proofofPrincipalPhotographyReceivedDate
      ) {
        return;
      }
      const proofofPrincipalPhotographyReceivedDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.proofofPrincipalPhotographyReceivedDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (proofofPrincipalPhotographyReceivedDateIndex >= 0) {
        projectDates[proofofPrincipalPhotographyReceivedDateIndex].date =
          initCert.initialCertCriticalDates.proofofPrincipalPhotographyReceivedDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.proofofPrincipalPhotographyReceivedDate.name;
        newDate.category =
          dateTypes.proofofPrincipalPhotographyReceivedDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date =
          initCert.initialCertCriticalDates.proofofPrincipalPhotographyReceivedDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.postProductionCostReportDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <InitialCertification>form;
      if (!initCert.initialCertCriticalDates.postProductionCostReportDate) {
        return;
      }
      const postProductionCostReportDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.postProductionCostReportDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (postProductionCostReportDateIndex >= 0) {
        projectDates[postProductionCostReportDateIndex].date =
          initCert.initialCertCriticalDates.postProductionCostReportDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.postProductionCostReportDate.name;
        newDate.category = dateTypes.postProductionCostReportDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date =
          initCert.initialCertCriticalDates.postProductionCostReportDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction:
      updateFormDateActions.principalPhotographyStartDueDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <InitialCertification>form;
      if (!initCert.initialCertCriticalDates.principalPhotographyStartDueDate) {
        return;
      }
      const principalPhotographyStartDueDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.photographyStartDueDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (principalPhotographyStartDueDateIndex >= 0) {
        projectDates[principalPhotographyStartDueDateIndex].date =
          initCert.initialCertCriticalDates.principalPhotographyStartDueDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.photographyStartDueDate.name;
        newDate.category = dateTypes.photographyStartDueDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date =
          initCert.initialCertCriticalDates.principalPhotographyStartDueDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.relatedApplicationReceivedDateChanged,
    incentiveProgram: DM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <DigitalInitialCertification>form;
      if (!initCert.initialCertCriticalDates.relatedApplicationReceivedDate) {
        return;
      }
      const relatedApplicationReceivedDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.relatedApplicationReceivedDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (relatedApplicationReceivedDateIndex >= 0) {
        projectDates[relatedApplicationReceivedDateIndex].date =
          initCert.initialCertCriticalDates.relatedApplicationReceivedDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.relatedApplicationReceivedDate.name;
        newDate.category = dateTypes.relatedApplicationReceivedDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date =
          initCert.initialCertCriticalDates.relatedApplicationReceivedDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.initialCertIssuanceDateChanged,
    incentiveProgram: DM,
    formType: formTypes.initialCert.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const initCert = <DigitalInitialCertification>form;
      if (!initCert.initialCertCriticalDates.initialCertIssuanceDate) {
        return;
      }
      const initialCertIssuanceDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.initialCertIssuanceDate.name &&
          pd.formId.formType === formTypes.initialCert.abbrev &&
          pd.formId.formIndex === initCert.formIndex
      );
      if (initialCertIssuanceDateIndex >= 0) {
        projectDates[initialCertIssuanceDateIndex].date =
          initCert.initialCertCriticalDates.initialCertIssuanceDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.initialCertIssuanceDate.name;
        newDate.category = dateTypes.initialCertIssuanceDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date =
          initCert.initialCertCriticalDates.initialCertIssuanceDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.actualExpenditureStartDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <Audit>form;
      if (!audit.auditCriticalDates.actualExpenditureStartDate) {
        return;
      }
      const actualExpenditureStartDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.actualExpenditureStartDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (actualExpenditureStartDateIndex >= 0) {
        projectDates[actualExpenditureStartDateIndex].date =
          audit.auditCriticalDates.actualExpenditureStartDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.actualExpenditureStartDate.name;
        newDate.category = dateTypes.actualExpenditureStartDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.actualExpenditureStartDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.actualExpenditureEndDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <Audit>form;
      if (!audit.auditCriticalDates.actualExpenditureEndDate) {
        return;
      }
      const actualExpenditureEndDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.actualExpenditureEndDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (actualExpenditureEndDateIndex >= 0) {
        projectDates[actualExpenditureEndDateIndex].date =
          audit.auditCriticalDates.actualExpenditureEndDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.actualExpenditureEndDate.name;
        newDate.category = dateTypes.actualExpenditureEndDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.actualExpenditureEndDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.auditDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <Audit>form;
      if (!audit.auditCriticalDates.auditDate) {
        return;
      }
      const auditDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.auditDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (auditDateIndex >= 0) {
        projectDates[auditDateIndex].date = audit.auditCriticalDates.auditDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.auditDate.name;
        newDate.category = dateTypes.auditDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.auditDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.finalCertIssuanceDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <Audit>form;
      if (!audit.auditCriticalDates.finalCertIssuanceDate) {
        return;
      }
      const finalCertIssuanceDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.finalCertIssuanceDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (finalCertIssuanceDateIndex >= 0) {
        projectDates[finalCertIssuanceDateIndex].date =
          audit.auditCriticalDates.finalCertIssuanceDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.finalCertIssuanceDate.name;
        newDate.category = dateTypes.finalCertIssuanceDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.finalCertIssuanceDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.closureDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <Audit>form;
      if (!audit.auditCriticalDates.closureDate) {
        return;
      }
      const closureDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.closureDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (closureDateIndex >= 0) {
        projectDates[closureDateIndex].date =
          audit.auditCriticalDates.closureDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.closureDate.name;
        newDate.category = dateTypes.closureDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.closureDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.costReportReceivedDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <Audit>form;
      if (!audit.auditCriticalDates.costReportReceivedDate) {
        return;
      }
      const costReportReceivedDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.costReportReceivedDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (costReportReceivedDateIndex >= 0) {
        projectDates[costReportReceivedDateIndex].date =
          audit.auditCriticalDates.costReportReceivedDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.costReportReceivedDate.name;
        newDate.category = dateTypes.costReportReceivedDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.costReportReceivedDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.verificationReportReceivedDateChanged,
    incentiveProgram: FILM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <Audit>form;
      if (!audit.auditCriticalDates.verificationReportReceivedDate) {
        return;
      }
      const verificationReportReceivedDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.verificationReportReceivedDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (verificationReportReceivedDateIndex >= 0) {
        projectDates[verificationReportReceivedDateIndex].date =
          audit.auditCriticalDates.verificationReportReceivedDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.verificationReportReceivedDate.name;
        newDate.category = dateTypes.verificationReportReceivedDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.verificationReportReceivedDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.auditDateChanged,
    incentiveProgram: DM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <DigitalAudit>form;
      if (!audit.auditCriticalDates.auditDate) {
        return;
      }
      const auditDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.auditDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (auditDateIndex >= 0) {
        projectDates[auditDateIndex].date = audit.auditCriticalDates.auditDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.auditDate.name;
        newDate.category = dateTypes.auditDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.auditDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.finalCertIssuanceDateChanged,
    incentiveProgram: DM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <DigitalAudit>form;
      if (!audit.auditCriticalDates.finalCertIssuanceDate) {
        return;
      }
      const finalCertIssuanceDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.finalCertIssuanceDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (finalCertIssuanceDateIndex >= 0) {
        projectDates[finalCertIssuanceDateIndex].date =
          audit.auditCriticalDates.finalCertIssuanceDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.finalCertIssuanceDate.name;
        newDate.category = dateTypes.finalCertIssuanceDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.finalCertIssuanceDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.closureDateChanged,
    incentiveProgram: DM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <DigitalAudit>form;
      if (!audit.auditCriticalDates.closureDate) {
        return;
      }
      const closureDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.closureDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (closureDateIndex >= 0) {
        projectDates[closureDateIndex].date =
          audit.auditCriticalDates.closureDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.closureDate.name;
        newDate.category = dateTypes.closureDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.closureDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.costReportReceivedDateChanged,
    incentiveProgram: DM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <DigitalAudit>form;
      if (!audit.auditCriticalDates.costReportReceivedDate) {
        return;
      }
      const costReportReceivedDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.costReportReceivedDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (costReportReceivedDateIndex >= 0) {
        projectDates[costReportReceivedDateIndex].date =
          audit.auditCriticalDates.costReportReceivedDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.costReportReceivedDate.name;
        newDate.category = dateTypes.costReportReceivedDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.costReportReceivedDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: updateFormDateActions.verificationReportReceivedDateChanged,
    incentiveProgram: DM,
    formType: formTypes.audit.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const audit = <DigitalAudit>form;
      if (!audit.auditCriticalDates.verificationReportReceivedDate) {
        return;
      }
      const verificationReportReceivedDateIndex = projectDates.findIndex(
        pd =>
          pd.type === dateTypes.verificationReportReceivedDate.name &&
          pd.formId.formType === formTypes.audit.abbrev &&
          pd.formId.formIndex === audit.formIndex
      );
      if (verificationReportReceivedDateIndex >= 0) {
        projectDates[verificationReportReceivedDateIndex].date =
          audit.auditCriticalDates.verificationReportReceivedDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.verificationReportReceivedDate.name;
        newDate.category = dateTypes.verificationReportReceivedDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.formId.formIndex = form.formIndex;
        newDate.formId.formType = form.type;
        newDate.created = new Date();
        newDate.date = audit.auditCriticalDates.verificationReportReceivedDate;
        projectDates.push(newDate);
      }
    }
  },
  {
    formDateAction: formStatuses.received.name,
    incentiveProgram: ITE,
    formType: formTypes.advance.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const advance = <Advance>form;
      const advanceProjectEndDate = new Date(
        advance.projectDetails.projectEndDate
      );
      //#region Advance Expiration Date
      const advanceExpirationDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.advanceExpirationDate.name
      );
      const newAdvanceExpirationDate = addYears(advanceProjectEndDate, 1);
      if (advanceExpirationDateIndex >= 0) {
        projectDates[
          advanceExpirationDateIndex
        ].date = newAdvanceExpirationDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.advanceExpirationDate.name;
        newDate.category = dateTypes.advanceExpirationDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newAdvanceExpirationDate;
        projectDates.push(newDate);
      }
      //#endregion
      //#region Application Due Date
      const applicationDueDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.applicationDueDate.name
      );
      const newappplicationDueDate = addDays(advanceProjectEndDate, 90);
      if (applicationDueDateIndex >= 0) {
        projectDates[applicationDueDateIndex].date = newappplicationDueDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.applicationDueDate.name;
        newDate.category = dateTypes.applicationDueDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newappplicationDueDate;
        projectDates.push(newDate);
      }
      //#endregion
    }
  },
  {
    formDateAction: formStatuses.received.name,
    incentiveProgram: RTA,
    formType: formTypes.advance.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const advance = <Advance>form;
      const advanceProjectEndDate = new Date(
        advance.projectDetails.projectEndDate
      );
      const advanceProjectStartDate = new Date(
        advance.projectDetails.projectStartDate
      );
      //#region Advance Expiration Date
      const advanceExpirationDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.advanceExpirationDate.name
      );
      const newAdvanceExpirationDate = addYears(advanceProjectEndDate, 1);
      if (advanceExpirationDateIndex >= 0) {
        projectDates[
          advanceExpirationDateIndex
        ].date = newAdvanceExpirationDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.advanceExpirationDate.name;
        newDate.category = dateTypes.advanceExpirationDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newAdvanceExpirationDate;
        projectDates.push(newDate);
      }
      //#endregion
      //#region Application Due Date
      const applicationDueDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.applicationDueDate.name
      );
      const newappplicationDueDate = addDays(advanceProjectStartDate, 90);
      if (applicationDueDateIndex >= 0) {
        projectDates[applicationDueDateIndex].date = newappplicationDueDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.applicationDueDate.name;
        newDate.category = dateTypes.applicationDueDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newappplicationDueDate;
        projectDates.push(newDate);
      }
      //#endregion
    }
  },
  {
    formDateAction: formStatuses.received.name,
    incentiveProgram: EZ,
    formType: formTypes.advance.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const advance = <Advance>form;
      const advanceProjectEndDate = new Date(
        advance.projectDetails.projectEndDate
      );
      //#region Advance Expiration Date
      const advanceExpirationDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.advanceExpirationDate.name
      );

      const postAct182021Rules = new Date() >= new Date('7/20/2021');

      const postAct182021RulesDate = addDays(
        advanceProjectEndDate,
        90
      );

      const newAdvanceExpirationDate = postAct182021Rules ?
          postAct182021RulesDate :
          addDays(addYears(advanceProjectEndDate, 1), -1);

      if (advanceExpirationDateIndex >= 0) {
        projectDates[
          advanceExpirationDateIndex
        ].date = newAdvanceExpirationDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.advanceExpirationDate.name;
        newDate.category = dateTypes.advanceExpirationDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newAdvanceExpirationDate;
        projectDates.push(newDate);
      }
      //#endregion
      //#region Application Due Date
      const applicationDueDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.applicationDueDate.name
      );
      if (applicationDueDateIndex >= 0) {
        projectDates[applicationDueDateIndex].date = newAdvanceExpirationDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.applicationDueDate.name;
        newDate.category = dateTypes.applicationDueDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newAdvanceExpirationDate;
        projectDates.push(newDate);
      }
      //#endregion
    }
  },
  {
    formDateAction: formStatuses.received.name,
    incentiveProgram: QJ,
    formType: formTypes.advance.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const advance = <Advance>form;
      //#region Advance Expiration Date
      const advanceExpirationDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.advanceExpirationDate.name
      );
      const newAdvanceExpirationDate = addDays(
        addMonths(new Date(advance.receivedDate), 24),
        -1
      );
      if (advanceExpirationDateIndex >= 0) {
        projectDates[
          advanceExpirationDateIndex
        ].date = newAdvanceExpirationDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.advanceExpirationDate.name;
        newDate.category = dateTypes.advanceExpirationDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newAdvanceExpirationDate;
        projectDates.push(newDate);
      }
      //#endregion
      //#region Application Due Date
      const applicationDueDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.applicationDueDate.name
      );
      const newApplicationDueDate = addDays(
        addMonths(new Date(advance.receivedDate), 24),
        -1
      );
      if (applicationDueDateIndex >= 0) {
        projectDates[applicationDueDateIndex].date = newApplicationDueDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.applicationDueDate.name;
        newDate.category = dateTypes.applicationDueDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newApplicationDueDate;
        projectDates.push(newDate);
      }
      //#endregion
    }
  },
  {
    formDateAction: formStatuses.received.name,
    incentiveProgram: RTA,
    formType: formTypes.pcr.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {
      const pcr = form as ProjectCompletionReport;
      const projectInfo = data as ProjectInfoBase;

      //#region Contract Effective Date
      const contractEffectiveDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.contractEffectiveDate.name
      );
      const getContractEffectiveDate = (parish: string): Date => {
        const refDate =
          new Date(pcr.operationStartDate) < new Date(pcr.endDate)
            ? new Date(pcr.operationStartDate)
            : new Date(pcr.endDate);
        if (parish === 'Orleans') {
          const year =
            refDate.getMonth() < 7
              ? refDate.getFullYear()
              : refDate.getFullYear() + 1;
          return new Date(year, 7, 1);
        }
        return new Date(refDate.getFullYear(), 0, 1);
      };
      const newContractEffectiveDate = getContractEffectiveDate(
        projectInfo.location.parish
      );
      if (contractEffectiveDateIndex >= 0) {
        projectDates[
          contractEffectiveDateIndex
        ].date = newContractEffectiveDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.contractEffectiveDate.name;
        newDate.category = dateTypes.contractEffectiveDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newContractEffectiveDate;
        projectDates.push(newDate);
      }
      //#endregion

      //#region Contract Expiration Date
      const contractExpirationDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.contractExpirationDate.name
      );
      const getContractExpirationDate = (parish: string): Date => {
        const refDate =
          new Date(pcr.operationStartDate) < new Date(pcr.endDate)
            ? new Date(pcr.operationStartDate)
            : new Date(pcr.endDate);
        if (parish === 'Orleans') {
          const year =
            refDate.getMonth() < 7
              ? refDate.getFullYear()
              : refDate.getFullYear() + 1;
          return new Date(year + 5, 6, 31);
        }
        return new Date(refDate.getFullYear() + 5, 11, 31);
      };
      const newContractExpirationDate = getContractExpirationDate(
        projectInfo.location.parish
      );
      if (contractExpirationDateIndex >= 0) {
        projectDates[
          contractExpirationDateIndex
        ].date = newContractExpirationDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.contractExpirationDate.name;
        newDate.category = dateTypes.contractExpirationDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newContractExpirationDate;
        projectDates.push(newDate);
      }
      //#endregion

      //#region Renewal Due Date
      const renewalDueDateIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.renewalApplicationDueDate.name
      );
      const newRenewalDueDate = addMonths(newContractExpirationDate, 6);
      if (renewalDueDateIndex >= 0) {
        projectDates[renewalDueDateIndex].date = newRenewalDueDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.renewalApplicationDueDate.name;
        newDate.category = dateTypes.renewalApplicationDueDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newRenewalDueDate;
        projectDates.push(newDate);
      }
      //#endregion

      //#region Renewal Application Eligibility Date
      const renewalApplicationEligibleIndex = projectDates.findIndex(
        pd => pd.type === dateTypes.renewalApplicationEligibilityDate.name
      );
      const newRenewalEligibilityDate = addMonths(
        newContractExpirationDate,
        -6
      );
      if (renewalApplicationEligibleIndex >= 0) {
        projectDates[
          renewalApplicationEligibleIndex
        ].date = newRenewalEligibilityDate;
      } else {
        const newDate = new ProjectDate();
        newDate.type = dateTypes.renewalApplicationEligibilityDate.name;
        newDate.category = dateTypes.renewalApplicationEligibilityDate.category;
        newDate.formId = new FormIdentifier();
        newDate.formId.projectGuid = projectId;
        newDate.created = new Date();
        newDate.date = newRenewalEligibilityDate;
        projectDates.push(newDate);
      }
      //#endregion
    }
  },
  {
    formDateAction: formStatuses.received.name,
    incentiveProgram: ITE,
    formType: formTypes.coc.abbrev,
    handler: (
      projectDates: ProjectDate[],
      form: ProjectForm,
      projectId: string,
      data: any
    ) => {}
  }
];
export const incentiveCategory = {
  business: {
    name: 'Business',
    abbrev: 'business',
    incentivePrograms: [QJ, RTA, ITE, EZ]
  },
  entertainment: {
    name: 'Entertainment & Media',
    abbrev: 'entertainment',
    incentivePrograms: [FILM, DM]
  },
  step: {
    name: 'State Trade Expansion Program',
    abbrev: 'step',
    incentivePrograms: [STEP]
  }
};

/**
 * @summary Use this when seeking to autocomplete or validate program types
 */
export type IncentiveProgram = 'QJ' | 'RTA' | 'ITE' | 'EZ' | 'FILM' | 'DM' | 'STEP';

export const accessStatuses = {
  pending: {
    name: 'Pending',
    abbrev: 'pending',
    value: 0
  },
  assigned: {
    name: 'Assigned',
    abbrev: 'assigned',
    value: 1
  },
  denied: {
    name: 'Denied',
    abbrev: 'denied',
    value: 2
  }
};

export const incentiveProgram = {
  dm: {
    boardOrder: -1,
    category: incentiveCategory.entertainment.abbrev,
    code: DM,
    name: 'Digital'
  },
  ez: {
    boardOrder: 2,
    category: incentiveCategory.business.abbrev,
    code: EZ,
    name: 'Enterprise Zone'
  },
  film: {
    boardOrder: -1,
    category: incentiveCategory.entertainment.abbrev,
    code: FILM,
    name: 'Film'
  },
  ite: {
    boardOrder: 3,
    category: incentiveCategory.business.abbrev,
    code: ITE,
    name: 'Industrial Tax Exemption'
  },
  qj: {
    boardOrder: 0,
    category: incentiveCategory.business.abbrev,
    code: QJ,
    name: 'Quality Jobs'
  },
  rta: {
    boardOrder: 1,
    category: incentiveCategory.business.abbrev,
    code: RTA,
    name: 'Restoration Tax Abatement'
  },
  step: {
    boardOrder: -1,
    category: incentiveCategory.step.abbrev,
    code: STEP,
    name: 'State Trade Expansion Program'
  }
};

export const projectStatus = {
  projectInReview: 'Project In Review',
  projectWithdrawn: 'Project Withdrawn',
  renewalContractComplete: 'Renewal Contract Complete',
  contractComplete: 'Contract Complete',
  projectPending: 'Project Pending',
  contractPending: 'Contract Pending',
  renewalContractPending: 'Renewal Contract Pending',
  contractActive: 'Contract Active',
  renewalContractActive: 'Renewal Contract Active',
  contractCancelled: 'Contract Cancelled',
  renewalContractCancelled: 'Renewal Contract Cancelled',
  projectDenied: 'Project Denied',
    projectActive: 'Project Active',
  projectClosed: 'Project Closed'
};

export const entProjectStatus = {
  projectPending: 'Project Pending',
  projectInReview: 'Project In Review',
  projectWithdrawn: 'Project Withdrawn',
  initialCertDenied: 'Initial Certification Denied',
  initialCertAppeal: 'Initial Certification Under Appeal',
  projectActive: 'Project Active', // ENT ONLY
  projectComplete: 'Project Complete', // ENT ONLY
  finalCertDenied: 'Final Certification Denied', // ENT ONLY
  finalCertAppeal: 'Final Certification Under Appeal', // ENT ONLY
  projectClosed: 'Project Closed', // ENT ONLY
  historical: 'Historical',
  projectDenied: 'Project Denied'
};

export const stepProjectStatus = {
  projectPending: 'Project Pending',
  projectInReview: 'Project In Review',
  projectWithdrawn: 'Project Withdrawn',
  projectActive: 'Project Active',
  projectComplete: 'Project Complete',
  projectClosed: 'Project Closed',
  historical: 'Historical',
  projectDenied: 'Project Denied'
};

export const projectStatuses = [
  { name: projectStatus.projectInReview },
  { name: projectStatus.projectWithdrawn },
  { name: projectStatus.renewalContractComplete },
  { name: projectStatus.contractComplete },
  { name: projectStatus.projectPending },
  { name: projectStatus.contractPending },
  { name: projectStatus.renewalContractPending },
  { name: projectStatus.contractActive },
  { name: projectStatus.renewalContractActive },
  { name: projectStatus.contractCancelled },
  { name: projectStatus.renewalContractCancelled },
  { name: projectStatus.projectDenied },
  { name: projectStatus.projectClosed }
];

export const fiscalYears = [
  { name: '1996', value: '1996' },
  { name: '1997', value: '1997' },
  { name: '1998', value: '1998' },
  { name: '1999', value: '1999' },
  { name: '2000', value: '2000' },
  { name: '2001', value: '2001' },
  { name: '2002', value: '2002' },
  { name: '2003', value: '2003' },
  { name: '2004', value: '2004' },
  { name: '2005', value: '2005' },
  { name: '2006', value: '2006' },
  { name: '2007', value: '2007' },
  { name: '2008', value: '2008' },
  { name: '2009', value: '2009' },
  { name: '2010', value: '2010' },
  { name: '2011', value: '2011' },
  { name: '2012', value: '2012' },
  { name: '2013', value: '2013' },
  { name: '2014', value: '2014' },
  { name: '2015', value: '2015' },
  { name: '2016', value: '2016' },
  { name: '2017', value: '2017' },
  { name: '2018', value: '2018' },
  { name: '2019', value: '2019' },
  { name: '2020', value: '2020' },
  { name: '2021', value: '2021' },
  { name: '2022', value: '2022' },
  { name: '2023', value: '2023' },
  { name: '2024', value: '2024' },
  { name: '2025', value: '2025' },
  { name: '2026', value: '2026' },
  { name: '2027', value: '2027' },
  { name: '2028', value: '2028' },
  { name: '2029', value: '2029' },
  { name: '2030', value: '2030' },
  { name: '2031', value: '2031' },
  { name: '2032', value: '2032' },
  { name: '2033', value: '2033' },
  { name: '2034', value: '2034' },
  { name: '2035', value: '2035' },
  { name: '2036', value: '2036' },
  { name: '2037', value: '2037' },
  { name: '2038', value: '2038' },
  { name: '2039', value: '2039' },
  { name: '2040', value: '2040' }
];

export const entProjectStatuses = [
  entProjectStatus.projectPending,
  entProjectStatus.projectInReview,
  entProjectStatus.projectWithdrawn,
  entProjectStatus.initialCertDenied,
  entProjectStatus.initialCertAppeal,
  entProjectStatus.projectActive,
  entProjectStatus.projectComplete,
  entProjectStatus.finalCertDenied,
  entProjectStatus.finalCertAppeal,
  entProjectStatus.projectClosed,
  entProjectStatus.historical,
  entProjectStatus.projectDenied
];

export const stepProjectStatuses = [
  stepProjectStatus.projectPending,
  stepProjectStatus.projectInReview,
  stepProjectStatus.projectWithdrawn,
  stepProjectStatus.projectActive,
  stepProjectStatus.projectComplete,
  stepProjectStatus.projectClosed,
  stepProjectStatus.historical,
  stepProjectStatus.projectDenied
];

export const projectType = {
  addition: 'Addition',
  development: 'Development',
  expansion: 'Expansion',
  improvement: 'Improvement',
  misc: 'Miscellaneous Capital Addition',
  renovation: 'Renovation',
  restoration: 'Restoration',
  startup: 'Start-Up/New'
};

export const externalReviewStatuses = {
  pendingReview: {
    name: 'Pending Review',
    abbrev: 'pendingReview',
    displayName: 'Pending Review'
  },
  noObjection: {
    name: 'No Objection',
    abbrev: 'noObjection',
    displayName: 'No Objection'
  },
  objection: {
    name: 'Objection',
    abbrev: 'objection',
    displayName: 'Objection'
  },
  objectionLifted: {
    name: 'Objection Lifted',
    abbrev: 'objectionLifted',
    displayName: 'Objection Lifted'
  }
};

// This constant is used to populate the dropdownmenu in the LGA portal.
export const lgaExternalReviewStatuses = {
  pendingReview: {
    name: 'Pending Review',
    abbrev: 'pendingReview',
    displayName: 'Pending Review'
  },
  approved: {
    name: 'No Objection',
    abbrev: 'noObjection',
    displayName: 'Approved'
  },
  denied: {
    name: 'Objection',
    abbrev: 'objection',
    displayName: 'Denied'
  }
};

export const externalReviewAgencies = {
  ldr: {
    code: 'revenue',
    abbrev: 'LDR',
    name: 'Louisiana Department of Revenue'
  },
  led: {
    code: 'economicDevelopmentSecretary',
    abbrev: 'LED Secretary',
    name: 'LED Secretary'
  },
  lga: {
    code: 'localGoverningAuthorities',
    abbrev: 'LGA',
    name: 'Local Governing Authority'
  },
  lwc: {
    code: 'workforceComission',
    abbrev: 'LWC',
    name: 'Louisiana Workforce Commission'
  }
};

export const actNumber = {
  QJ: {
    abbrev: 'QJ',
    threshold: new Date('2017-07-01'),
    old: 387,
    new: 386
  },
  EZ: {
    abbrev: 'EZ',
    threshold: new Date('2016-04-01'),
    old: 126,
    new: 18
  }
};

export const sessionNumber = {
  QJ: {
    abbrev: 'QJ',
    threshold: new Date('2017-07-01'),
    old: '',
    new: ', 2017 Session'
  },
  EZ: {
    abbrev: 'EZ',
    threshold: new Date('2016-04-01'),
    old: ', 2015 Session',
    new: ', 2016 1st Extraordinary Session'
  }
};

export const soloFormTypes = (function() {
  return [
    formTypes.advance.abbrev,
    formTypes.application.abbrev,
    formTypes.renewalApplication.abbrev
  ];
})();

export const legislationRuleNames = {
  ez: {
    postAct18_2021: {
      abbrev: 'postAct18_2021',
      name: 'Post Act 18; 2021 Rules'
    },
    postAct18_2016: {
      abbrev: 'postAct18_2016',
      name: 'Post Act 18, 2016 Session'
    },
    postAct423_2015: {
      abbrev: 'postAct423_2015',
      name: 'Post Act 423 & Act 126, 2015 Session'
    },
    postAct423_2014: {
      abbrev: 'postAct423_2014',
      name: 'Post Act 423, 2014 Session'
    },
    preAct423: {
      abbrev: 'preAct423',
      name: 'Pre Act 423'
    }
  },
  qj: {
    Act126_Act386_2017Session: {
      abbrev: 'Act126_Act386_2017Session',
      name: 'Act 126 & Act 386, 2017 Session'
    },
    Act387_Act126Session: {
      abbrev: 'Act387_Act126Session',
      name: 'Act 387 & Act 126'
    },
    Act387: {
      abbrev: 'Act387',
      name: 'Act 387'
    },
    PreAct387: {
      abbrev: 'PreAct387',
      name: 'Pre Act 387'
    }
  },
  ite: {
    postExeOrd_2018: {
      abbrev: 'postExeOrd_2018',
      name: 'Post Executive Order 2018',
      boardOrder: 3
    },
    postExeOrd_2017: {
      abbrev: 'postExeOrd_2017',
      name: 'Post Executive Order 2017',
      boardOrder: 2
    },
    PreExeOrd: {
      abbrev: 'PreExeOrd',
      name: 'Pre Executive Order',
      boardOrder: 1
    },
    EmergencyRules2024: {
      abbrev: 'EmergencyRules2024',
      name: '2024 Emergency Rules',
      boardOrder: 0
    }
  },
  rta: {
    Const_7_21: {
      abbrev: 'Const_7_21',
      name: 'Const 7 21'
    }
  },
  film: {
    july2017: {
      abbrev: 'july2017',
      name: 'July 2017'
    },
    july2015: {
      abbrev: 'july2015',
      name: 'July 2015'
    },
    preJuly2015: {
      abbrev: 'preJuly2015',
      name: 'Pre July 2015'
    }
  },
  dm: {
    july2018: {
      abbrev: 'july2018',
      name: 'July 2018'
    },
    july2015: {
      abbrev: 'july2015',
      name: 'July 2015'
    },
    preJuly2015: {
      abbrev: 'preJuly2015',
      name: 'Pre July 2015'
    }
  }
};

export const legistlationRules = {
  ez: [
    {
      ruleName: 'Post Act 18; 2021 Rules',
      effectiveDate: new Date('2021-07-20')
    },
    {
      ruleName: 'Post Act 18, 2016 Session',
      effectiveDate: new Date('2016-04-01')
    },
    {
      ruleName: 'Post Act 423 & Act 126, 2015 Session',
      effectiveDate: new Date('2016-03-31')
    },
    {
      ruleName: 'Post Act 423, 2014 Session',
      effectiveDate: new Date('2015-07-01')
    },
    {
      ruleName: 'Pre Act 423',
      effectiveDate: null
    }
  ],
  qj: [
    {
      ruleName: 'Act 126 & Act 386, 2017 Session',
      effectiveDate: new Date('2017-07-01')
    },
    {
      ruleName: 'Act 387 & Act 126',
      effectiveDate: new Date('2015-07-01')
    },
    {
      ruleName: 'Act 387',
      effectiveDate: new Date('2007-07-01')
    },
    {
      ruleName: 'Pre Act 387',
      effectiveDate: null
    }
  ],
  ite: [
    // todo: this rule set has not been defined yet - fot future
    {
      ruleName: '2024 Emergency Rules',
      effectiveDate: new Date('2024-02-21')
    },
    {
      ruleName: 'Post Executive Order 2018',
      effectiveDate: new Date('2018-04-25')
    },
    {
      ruleName: 'Post Executive Order 2017',
      effectiveDate: new Date('2016-06-24')
    },
    {
      ruleName: 'Pre Executive Order',
      effectiveDate: null
    }
  ],
  rta: [
    {
      ruleName: 'Const 7 21',
      effectiveDate: null
    }
  ],
  film: [
    {
      ruleName: legislationRuleNames.film.july2017.name,
      effectiveDate: new Date('2017-07-01')
    },
    {
      ruleName: legislationRuleNames.film.july2015.name,
      effectiveDate: new Date('2015-07-01')
    },
    {
      ruleName: legislationRuleNames.film.preJuly2015.name,
      effectiveDate: null
    }
  ],
  [incentiveProgram.dm.code.toLowerCase()]: [
    {
      ruleName: legislationRuleNames.dm.july2018.name,
      effectiveDate: new Date('2018-07-01')
    },
    {
      ruleName: legislationRuleNames.dm.july2015.name,
      effectiveDate: new Date('2015-07-01')
    },
    {
      ruleName: legislationRuleNames.dm.preJuly2015.name,
      effectiveDate: null
    }
  ]
};

export const morphingLegislationRules: {
  [legislationRule: string]: string[];
} = {
  [legislationRuleNames.ite.postExeOrd_2017.name]: [
    legislationRuleNames.ite.postExeOrd_2017.name,
    legislationRuleNames.ite.postExeOrd_2018.name
  ]
};

export const responseStatusCodes = {
  conflict: 409
};

/**
 * @summary The forms provided by the constant dictate which forms initially set the
 * legislation rule and set the project to Project In Review
 */
export const firstProgramForm = {
  [incentiveProgram.ez.code]: [formTypes.advance.abbrev],
  [incentiveProgram.qj.code]: [formTypes.advance.abbrev],
  [incentiveProgram.rta.code]: [formTypes.advance.abbrev],
  [incentiveProgram.ite.code]: [formTypes.advance.abbrev],
  [incentiveProgram.film.code]: [formTypes.application.abbrev],
  [incentiveProgram.dm.code]: [formTypes.application.abbrev],
  [incentiveProgram.step.code]: [formTypes.application.abbrev],
};

export function updateDate(
  projectDates: ProjectDate[],
  index: number,
  years?: number,
  months?: number
) {
  const date = addYears(new Date(projectDates[index].date), years);
  return addMonths(date, months);
}

export const QJFormTypes = [
  formTypes.advance,
  formTypes.application,
  formTypes.acr,
  formTypes.afc,
  formTypes.balanceAdjustmentRequest,
  formTypes.changeAffiliatesOwners,
  formTypes.contractClosure,
  formTypes.changeLocation,
  formTypes.changeCompanyName,
  formTypes.fullTransferOwnership,
  formTypes.partialTransferOwnership,
  formTypes.ecr,
  formTypes.pcr,
  formTypes.specialRequest,
  formTypes.renewalApplication
];
export const EZFormTypes = [
  formTypes.advance,
  formTypes.application,
  formTypes.afc,
  formTypes.balanceAdjustmentRequest,
  formTypes.changeAffiliatesOwners,
  formTypes.contractClosure,
  formTypes.changeLocation,
  formTypes.changeCompanyName,
  formTypes.fullTransferOwnership,
  formTypes.partialTransferOwnership,
  formTypes.ecr,
  formTypes.extensionRequest,
  formTypes.pcr,
  formTypes.specialRequest,
  formTypes.renewalApplication
];
export const RTAFormTypes = [
  formTypes.advance,
  formTypes.application,
  formTypes.afc,
  formTypes.balanceAdjustmentRequest,
  formTypes.changeAffiliatesOwners,
  formTypes.contractClosure,
  formTypes.changeLocation,
  formTypes.changeCompanyName,
  formTypes.fullTransferOwnership,
  formTypes.partialTransferOwnership,
  formTypes.extensionRequest,
  formTypes.pcr,
  formTypes.specialRequest,
  formTypes.renewalApplication
];
export const ITEFormTypes = [
  formTypes.advance,
  formTypes.application,
  formTypes.afc,
  formTypes.balanceAdjustmentRequest,
  formTypes.changeAffiliatesOwners,
  formTypes.contractClosure,
  formTypes.changeLocation,
  formTypes.changeCompanyName,
  formTypes.coc,
  formTypes.fullTransferOwnership,
  formTypes.partialTransferOwnership,
  formTypes.extensionRequest,
  formTypes.pcr,
  formTypes.specialRequest,
  formTypes.renewalApplication
];
export const FILMFormTypes = [
  formTypes.application,
  formTypes.asa,
  formTypes.initialCert,
  formTypes.audit,
  formTypes.balanceAdjustmentRequest
];

export const DMFormTypes = [
  formTypes.application,
  formTypes.initialCert,
  formTypes.audit, //this should be removed and tested as audit is replaced by evr for DM (Leo - 12/14/2020)
  formTypes.evr,
  formTypes.balanceAdjustmentRequest
];

export const STEPFormTypes = [
  formTypes.stepApplication
];

export const hideFromLGAandAssessorsFormTypes = [
  formTypes.acr.abbrev,
  formTypes.balanceAdjustmentRequest.abbrev,
  formTypes.changeAffiliatesOwners.abbrev,
  formTypes.ecr.abbrev,
  formTypes.fullTransferOwnership.abbrev,
  formTypes.partialTransferOwnership.abbrev
];
/**
 * @summary Use this map to get all applicable formTypes per incentive program
 */
export const ProgramstoFormTypesMap = {
  [EZ]: EZFormTypes,
  [QJ]: QJFormTypes,
  [RTA]: RTAFormTypes,
  [ITE]: ITEFormTypes,
  [FILM]: FILMFormTypes,
  [DM]: DMFormTypes,
  [STEP]: STEPFormTypes,
};

/**
 * @summary Use this map to get all applicable project statuses per incentive program
 */
export const programsToProjectStatusesMap = {
  [EZ]: projectStatuses.map(ps => ps.name),
  [QJ]: projectStatuses.map(ps => ps.name),
  [RTA]: projectStatuses.map(ps => ps.name),
  [ITE]: projectStatuses.map(ps => ps.name),
  [FILM]: entProjectStatuses,
  [DM]: entProjectStatuses,
  [STEP]: stepProjectStatuses
};

/**
 * @summary Use this map to get all applicable form statuses per incentive program
 */
export const programsToFormStatusesMap = {
  [EZ]: Object.keys(formStatuses).map(fs => formStatuses[fs].name),
  [QJ]: Object.keys(formStatuses).map(fs => formStatuses[fs].name),
  [RTA]: Object.keys(formStatuses).map(fs => formStatuses[fs].name),
  [ITE]: Object.keys(formStatuses).map(fs => formStatuses[fs].name),
  [FILM]: Object.keys(entFormStatuses).map(fs => entFormStatuses[fs].name),
  [DM]: Object.keys(dmFormStatuses).map(fs => dmFormStatuses[fs].name),
  [STEP]: Object.keys(stepFormStatuses).map(fs => stepFormStatuses[fs].name)
};

/**
 * @summary Relates each form status to a corresponding category
 */
export const formStatusCategory = {
  pending: {
    name: <FormStatus>'Pending',
    order: 0,
    incentiveCategory: [
      incentiveCategory.business.abbrev,
      incentiveCategory.entertainment.abbrev
    ]
  },
  pendingSignature: {
    name: <FormStatus>'Pending Signature',
    order: 1,
    incentiveCategory: [
      incentiveCategory.business.abbrev,
      incentiveCategory.entertainment.abbrev
    ]
  },
  pendingPayment: {
    name: <FormStatus>'Pending Payment',
    order: 2,
    incentiveCategory: [
      incentiveCategory.business.abbrev,
      incentiveCategory.entertainment.abbrev
    ]
  },
  received: {
    name: <FormStatus>'Received',
    order: 3,
    incentiveCategory: [
      incentiveCategory.business.abbrev,
      incentiveCategory.entertainment.abbrev
    ]
  },
  informationRequired: {
    name: <FormStatus>'Information Required',
    order: 4,
    incentiveCategory: [
      incentiveCategory.business.abbrev,
      incentiveCategory.entertainment.abbrev
    ]
  },
  reviewOneComplete: {
    name: <FormStatus>'Review One Complete',
    order: 13,
    incentiveCategory: [
      incentiveCategory.entertainment.abbrev
    ]
  },
  reviewTwoComplete: {
    name: <FormStatus>'Review Two Complete',
    order: 14,
    incentiveCategory: [
      incentiveCategory.entertainment.abbrev
    ]
  },
  reviewComplete: {
    name: <FormStatus>'Review Complete',
    order: 5,
    incentiveCategory: [
      incentiveCategory.business.abbrev,
      incentiveCategory.entertainment.abbrev
    ]
  },
  onHold: {
    name: <FormStatus>'On Hold',
    order: 6,
    incentiveCategory: [
      incentiveCategory.business.abbrev,
      incentiveCategory.entertainment.abbrev
    ]
  },
  cancelled: {
    name: <FormStatus>'Cancelled',
    order: 7,
    incentiveCategory: [
      incentiveCategory.business.abbrev,
      incentiveCategory.entertainment.abbrev
    ]
  },
  denied: {
    name: <FormStatus>'Denied',
    order: 8,
    incentiveCategory: [
      incentiveCategory.business.abbrev,
      incentiveCategory.entertainment.abbrev
    ]
  },
  depositReceived: {
    name: <FormStatus>'Deposit Received',
    order: 9,
    incentiveCategory: [incentiveCategory.entertainment.abbrev]
  },
  auditorAssigned: {
    name: <FormStatus>'Auditor Assigned',
    order: 10,
    incentiveCategory: [incentiveCategory.entertainment.abbrev]
  },
  awaitingAudit: {
    name: <FormStatus>'Awaiting Audit',
    order: 11,
    incentiveCategory: [incentiveCategory.entertainment.abbrev]
  },
  awaitingExpenditureVerification: {
    name: <FormStatus>'Awaiting Expenditure Verification Report',
    order: 12,
    incentiveCategory: [incentiveCategory.entertainment.abbrev]
  }
};
