import { AuthenticationService } from '../../security/services/authentication.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { NavService } from '../../fastlane-common/services/nav.service';
import { AuthorizationToken } from '../../security/models/authorization-token.model';
import { userType } from '../user.constants';
import { Observable, throwError, asyncScheduler } from 'rxjs';
import { map, catchError, subscribeOn } from 'rxjs/operators';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';

@Injectable()
export class UserDataService {
  private readonly getBureauUrl = environment.apiUrl + '/user/bureau';
  private readonly getGovernorsUrl = environment.apiUrl + '/user/governors';
  private readonly getBoardMembersUrl =
    environment.apiUrl + '/user/boardmembers';
  private readonly getManagersUrl = environment.apiUrl + '/user/managers';
  private readonly getAuditorsUrl = environment.apiUrl + '/user/auditors';
  private readonly getAssessorsUrl =
    environment.apiUrl + '/user/assessors/{parish}';
  private readonly getUsersUrl = environment.apiUrl + '/user/all';
  private readonly getUsersDatatableUrl =
    environment.apiUrl + '/user/datatables';
  private readonly getUserUrl = environment.apiUrl + '/user/searchUser?id=';
  private readonly getUserUrlEmail = environment.apiUrl + '/user/searchUser?email=';
  private readonly getUserByType =
    environment.apiUrl + '/user/search?userType=';
  private readonly getUserIdUrl = `${
    environment.apiUrl
    }/user/id?emailAddress={emailAddress}`;
  private readonly createUserUrl = environment.apiUrl + '/user/create';
  private readonly updateUserUrl = environment.apiUrl + '/user/update';
  private readonly updateMyProfileUrl = environment.apiUrl + '/user/update/me';
  private readonly registerUserUrl = environment.apiUrl + '/user/register';
  private readonly verifyUserUrl = environment.apiUrl + '/user/verify/';
  private readonly getUserMyUserUrl = environment.apiUrl + '/user/me';
  private readonly resetPasswordUrl =
    environment.apiUrl + '/user/reset/password/';
  private readonly verifyPasswordResetTokenUrl =
    environment.apiUrl + '/user/validate/password/request/';
  private readonly resetMyPasswordTokenUrl =
    environment.apiUrl + '/user/reset/mypassword/';
  private cancellableDelay = 500;
  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private nav: NavService
  ) { }

  appendFromQueryParamsToUrl(url: string) {
    const urlHasQuestionMark = url.includes('?');
    const fromEmailDetails = `${
      urlHasQuestionMark ? '&' : '?'
      }fromEmailAddress=${encodeURIComponent(
        environment.emailFromAddress.emailAddress
      )}&fromName=${encodeURIComponent(
        environment.emailFromAddress.displayName
      )}`;

    return url + fromEmailDetails;
  }

  getBureau(): Observable<User> {
    return this.http
      .get(this.getBureauUrl, this.authService.getAuthOptions())
      .pipe(map(this.extractUserDataSingle))
      .pipe(catchError(this.handleError));
  }

  getGovernors(): Observable<User[]> {
    return this.http
      .get(this.getGovernorsUrl, this.authService.getAuthOptions())
      .pipe(map(this.extractUserDataMany))
      .pipe(catchError(this.handleError));
  }

  getBoardMembers(): Observable<User[]> {
    return this.http
      .get(this.getBoardMembersUrl, this.authService.getAuthOptions())
      .pipe(map(this.extractUserDataMany))
      .pipe(catchError(this.handleError));
  }

  getId(emailAddress: string): Observable<string> {
    const url = this.getUserIdUrl.replace('{emailAddress}', emailAddress);
    return this.http
      .get(url, this.authService.getAuthOptions())
      .pipe(map((res: string) => res));
  }

  getManagers(): Observable<User[]> {
    return this.http
      .get(this.getManagersUrl, this.authService.getAuthOptions())
      .pipe(map(this.extractUserDataMany))
      .pipe(catchError(this.handleError));
  }

  getManagersWithFilters(
    incentiveProgram: string,
    permission: string,
    evtType: string,
    evtAction: string
  ): Observable<User[]> {
    return this.http
      .get(
        this.getManagersUrl +
        `?incentiveProgram=${incentiveProgram}&permission=${permission}&evtType=${evtType}&evtAction=${evtAction}`,
        this.authService.getAuthOptions()
      )
      .pipe(map(this.extractUserDataMany))
      .pipe(catchError(this.handleError));
  }

  getAuditors(auditingCompanyId: string): Observable<User[]> {
    return this.http
      .get(
        this.getAuditorsUrl + `?auditingCompanyId=${auditingCompanyId}`,
        this.authService.getAuthOptions()
      )
      .pipe(map(this.extractUserDataMany))
      .pipe(catchError(this.handleError));
  }

  getAssessors(parish: string): Observable<User[]> {
    const url = this.getAssessorsUrl.replace('{parish}', parish);
    return this.http
      .get(url, this.authService.getAuthOptions())
      .pipe(map(this.extractUserDataMany))
      .pipe(catchError(this.handleError));
  }

  getExternalReviewers(): Observable<User[]> {
    const url = this.getUserByType + userType.external.code;
    return this.http
      .get(url, this.authService.getAuthOptions())
      .pipe(map(this.extractUserDataMany))
      .pipe(catchError(this.handleError));
  }

  getUsers(): Observable<User[]> {
    return this.http
      .get(this.getUsersUrl, this.authService.getAuthOptions())
      .pipe(
        subscribeOn(asyncScheduler),
        map(this.extractUserDataMany)
      )
      .pipe(catchError(this.handleError));
  }
  getUsersForDatatableCancellable(dataFilters: any) {
    let timerObj: any;

    const that = this;
    return new Observable<{
      data: User[];
      draw: number;
      recordsFiltered: number;
      recordsTotal: number;
    }>(subscriber => {
      timerObj = setTimeout(() => {
        this.http
          .post(
            this.getUsersDatatableUrl,
            dataFilters,
            this.authService.getAuthOptions()
          )
          .pipe(
            map(
              (res: {
                data: User[];
                draw: number;
                recordsFiltered: number;
                recordsTotal: number;
              }) => {
                if (res) {
                  const datatablesData = res;
                  datatablesData.data = datatablesData.data.map(el => {
                    const user = new User();
                    Object.assign(user, el);
                    return user;
                  });
                  return datatablesData;
                }

                return {
                  data: [],
                  draw: 0,
                  recordsFiltered: 0,
                  recordsTotal: 0
                };
              }
            )
          )
          .subscribe(
            datatablesData => {
              subscriber.next(datatablesData);
              subscriber.complete();
            },
            error => {
              subscriber.error(error);
              subscriber.complete();
            }
          );
      }, that.cancellableDelay);
      return () => clearTimeout(timerObj);
    });
  }
  getUser(id: string): Observable<User> {
    return this.http
      .get(this.getUserUrl + id, this.authService.getAuthOptions())
      .pipe(
        subscribeOn(asyncScheduler),
        map(this.extractUserDataSingle)
      )
      .pipe(catchError(this.handleError));
  }

  getUserByEmail(email: string): Observable<User> {
    return this.http
      .get(this.getUserUrlEmail + email, this.authService.getAuthOptions())
      .pipe(
        subscribeOn(asyncScheduler),
        map(this.extractUserDataSingle)
      )
      .pipe(catchError(this.handleError));
  }

  getMyUser() {
    return this.http
      .get(this.getUserMyUserUrl, this.authService.getAuthOptions())
      .pipe(
        subscribeOn(asyncScheduler),
        map(this.extractUserDataSingle)
      )
      .pipe(catchError(this.handleError));
  }

  createUser(user: User): Observable<User> {
    return this.http
      .post(
        this.createUserUrl,
        user,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map(this.extractUserDataSingle))
      .pipe(catchError(this.handleError));
  }

  registerUser(user: User): Observable<User> {
    const redirectionUrl = encodeURIComponent(
      this.nav.convertUrlTreeToString(['verify', '{{tok}}'])
    );
    let url = `${this.registerUserUrl}?redirectionUrl=${redirectionUrl}`;

    // * We need to add tester here so that we don't accidentally email real users when testing the application!
    if (
      environment.testEmailRecipientAddressess &&
      environment.testEmailRecipientAddressess.length > 0
    ) {
      environment.testEmailRecipientAddressess.forEach(testerEmailRecipient => {
        url += `&testerEmailAddress=${
          testerEmailRecipient.emailAddress
          }&testerName=${testerEmailRecipient.displayName}`;
      });
    }

    // Set from email params
    url = this.appendFromQueryParamsToUrl(url);

    return this.http
      .post(url, user, this.authService.getAuthOptions('application/json'))
      .pipe(map(this.extractUserDataSingle))
      .pipe(catchError(this.handleError));
  }

  updateUser(user: User): Observable<User> {
    return this.http
      .put(
        this.updateUserUrl,
        user,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map(this.extractUserDataSingle))
      .pipe(catchError(this.handleError));
  }

  updateMyUser(user: User): Observable<AuthorizationToken> {
    return this.http
      .put(
        this.updateMyProfileUrl,
        user,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(
        subscribeOn(asyncScheduler),
        map(this.extractUserToken)
      )
      .pipe(catchError(this.handleError));
  }

  verifyUser(
    verificationToken: string
  ): Observable<{ isVerified: boolean; reason?: string }> {
    const url = this.verifyUserUrl + verificationToken;
    return this.http
      .put(url, {})
      .pipe(
        subscribeOn(asyncScheduler),
        map((response: { isVerified: boolean; reason?: string }) => {
          return response;
        })
      )
      .pipe(catchError(this.handleError));
  }

  verifyPasswordResetToken(
    passwordResetToken: string
  ): Observable<{ isVerified: boolean; reason?: string }> {
    const url = this.verifyPasswordResetTokenUrl + passwordResetToken;
    return this.http
      .get(url, {})
      .pipe(
        subscribeOn(asyncScheduler),
        map((response: { isVerified: boolean; reason?: string }) => {
          return response;
        })
      )
      .pipe(catchError(this.handleError));
  }

  submitPasswordReset(tokenId: string, newPass: string): Observable<any> {
    let url = this.resetMyPasswordTokenUrl + tokenId;

    // Send data to be application/x-www-form-urlencoded
    const params = new HttpParams().set('plainPassword', newPass);

    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    // * We need to add tester here so that we don't accidentally email real users when testing the application!


    // Set from email params
    url = this.appendFromQueryParamsToUrl(url);
    if (
      environment.testEmailRecipientAddressess &&
      environment.testEmailRecipientAddressess.length > 0
    ) {
      environment.testEmailRecipientAddressess.forEach(testerEmailRecipient => {
        url += `&testerEmailAddress=${
          testerEmailRecipient.emailAddress
          }&testerName=${testerEmailRecipient.displayName}`;
      });
    }

    return this.http
      .put(url, params)
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }
          return {};
        })
      )
      .pipe(catchError(this.handleError));
  }

  resetPassword(
    emailAddress: string
  ): Observable<{ isVerified: boolean; reason?: string }> {
    const urlPrefix = this.resetPasswordUrl + emailAddress;

    const redirectionUrl = encodeURIComponent(
      this.nav.convertUrlTreeToString(['password-reset', '{{tok}}'])
    );
    let url = `${urlPrefix}?redirectionUrl=${redirectionUrl}`;

    // * We need to add tester here so that we don't accidentally email real users when testing the application!
    if (
      environment.testEmailRecipientAddressess &&
      environment.testEmailRecipientAddressess.length > 0
    ) {
      environment.testEmailRecipientAddressess.forEach(testerEmailRecipient => {
        url += `&testerEmailAddress=${
          testerEmailRecipient.emailAddress
          }&testerName=${testerEmailRecipient.displayName}`;
      });
    }

    // Set from email params
    url = this.appendFromQueryParamsToUrl(url);

    return this.http
      .get(url)
      .pipe(
        map((response: { isVerified: boolean; reason?: string }) => {
          return response;
        })
      )
      .pipe(catchError(this.handleError));
  }

  getResetRedirectionUrl(emailAddress: string): string {
    const urlPrefix = this.resetPasswordUrl + emailAddress;

    const redirectionUrl = this.nav.convertUrlTreeToString([
      'password-reset',
      '{{tok}}'
    ]);
    let url = `${urlPrefix}?redirectionUrl=${redirectionUrl}`;

    // * We need to add tester here so that we don't accidentally email real users when testing the application!
    if (
      environment.testEmailRecipientAddressess &&
      environment.testEmailRecipientAddressess.length > 0
    ) {
      environment.testEmailRecipientAddressess.forEach(testerEmailRecipient => {
        url += `&testerEmailAddress=${
          testerEmailRecipient.emailAddress
          }&testerName=${testerEmailRecipient.displayName}`;
      });
    }
    return redirectionUrl;
  }

  private extractUserDataMany(res: User[]) {
    if (res) {
      // we perform the following to convert the api's json to strongly typed objects
      const rawUserData = res;
      const userData = new Array<User>(rawUserData.length);
      for (let i = 0; i < userData.length; i++) {
        userData[i] = new User();
        Object.assign(userData[i], rawUserData[i]);
        userData[i].init();
      }
      return userData;
    }
    return [];
  }

  private extractUserDataSingle(res: User) {
    if (res) {
      // we perform the following to convert the api's json to strongly typed objects
      const rawUserData = res;
      const userData = new User();
      Object.assign(userData, rawUserData);
      userData.init();
      return userData;
    }
    return <User>{};
  }

  private handleError(error: HttpErrorResponse | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      errMsg = `${error.status} - ${error.statusText || ''} ${error.message}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return throwError(errMsg);
  }
  private extractUserToken(res: AuthorizationToken) {
    if (res) {
      // we perform the following to convert the api's json to strongly typed objects
      const rawToken = res;
      const token = new AuthorizationToken(rawToken);
      return token;
    }
    return <AuthorizationToken>{};
  }
}
