import { Application } from './film-application.model';
import { ProjectBase } from '../../../project/models/project-base.model';
import { InitialCertification } from './initial-certification.model';
import { ProjectInfo } from './project-info.model';
import { FormIdentifier } from '../../../project/models/form-identifier.model';
import { AdditionalSeasonApplication } from './additional-season-application.model';
import { EntertainmentProject } from '../../../entertainment/interfaces/project.interface';
import { Audit } from './audit.model';
import { AuditDepositCreator } from '../../interfaces/audit-deposit-creator.interface';
import { EntertainmentForm } from '../entertainment-form.model';
import { BalanceAdjustmentRequest } from '../../../project/models/balance-adjustment-request.model';
import { Evr } from '../digital/expenditure-verification.model';

export class Project extends ProjectBase implements EntertainmentProject {
  application: Application;
  public asa: AdditionalSeasonApplication[];
  audit: Audit[];
  initialCert: InitialCertification[];
  evr: Evr[];
  projectInfo: ProjectInfo;
  bar: BalanceAdjustmentRequest[];

  constructor() {
    super();
  }

  getAuditDepositCreatorForm(formId: FormIdentifier): AuditDepositCreator {
    return (this.getProjectForm(
      formId
    ) as EntertainmentForm) as AuditDepositCreator;
  }

  init(): Project {
    // Initialize the base class ProjectBase.
    super.init();

    if (this.application) {
      this.application = Object.assign(new Application(), this.application);
      this.application.init();
    }

    // ProjectInfo in ProjectBase is type ProjectInfoBase.
    // So, Super.init() takes care of initializing ProjectInfoBase in ProjectBase.
    // Here we just use that initialized projectInfoBase and construct FilmProjectInfo.
    this.projectInfo = new ProjectInfo(this.projectInfo);

    //#region onetomanyforms
    if (this.asa) {
      this.asa = this.asa.map(asa => {
        asa = Object.assign(new AdditionalSeasonApplication(), asa);
        asa.init();
        return asa;
      });
    }
    if (this.initialCert) {
      this.initialCert = this.initialCert.map(initCert => {
        initCert = Object.assign(new InitialCertification(), initCert);
        initCert.init();
        return initCert;
      });
    }

    if (this.audit) {
      this.audit = this.audit.map(audit => {
        audit = Object.assign(new Audit(), audit);
        audit.init();
        return audit;
      });
    }

    if (this.bar) {
      this.bar = this.bar.map(bar => {
        bar = Object.assign(new BalanceAdjustmentRequest(), bar);
        bar.init();
        return bar;
      });
    }
    //#endregion

    return this;
  }
}
